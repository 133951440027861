// src/pages/search/SearchResultsPage.tsx
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../../hooks/useToast';
import { 
 MapPin, Calendar, Users, Filter, Loader2, Star, 
 SortAsc, ArrowUpDown, Wifi, Timer, Search 
} from 'lucide-react';
import { format } from 'date-fns';
import { Button } from '../../components/ui/button';
import { Card, CardContent } from '../../components/ui/card';
import { Slider } from '../../components/ui/slider';
import { Switch } from '../../components/ui/switch';
import { Label } from '../../components/ui/label';
import {
 Select,
 SelectContent,
 SelectItem,
 SelectTrigger,
 SelectValue,
} from "@/components/ui/select";
import {
 Sheet,
 SheetContent,
 SheetDescription,
 SheetHeader,
 SheetTitle,
 SheetTrigger,
 SheetFooter,
} from '@/components/ui/sheet';
import { FEATURES_CONFIG } from '../../config/preferences';
import SearchForm from '@/components/search/SearchForm';
import HotelCard from '@/components/hotels/HotelCard';
import hotelService from '../../services/api/hotels/HotelService';
import { HotelPreview } from '@/types/hotelCategories';
import { UserPreferences } from '@/types/preferences';

interface SearchData {
 destination: string;
 destinationCode?: string;
 checkIn: Date;
 checkOut: Date;
 guests: number;
 preferences?: UserPreferences;
}

interface Filters {
 priceRange: [number, number];
 rating: number;
 features: Record<string, boolean>;
 distance: number;
 propertyTypes: string[];
}

interface SortOption {
 value: string;
 label: string;
}

const SORT_OPTIONS = {
 RECOMMENDED: 'recommended',
 PRICE_LOW: 'price_low',
 PRICE_HIGH: 'price_high',
 RATING: 'rating',
 DISTANCE: 'distance'
} as const;

type SortOptionType = typeof SORT_OPTIONS[keyof typeof SORT_OPTIONS];

const defaultFilters: Filters = {
 priceRange: [0, 1000],
 rating: 0,
 features: {},
 distance: 10,
 propertyTypes: []
};

const SearchResultsPage: React.FC = () => {
 const [searchParams] = useSearchParams();
 const { currentUser } = useAuth();
 const { toast } = useToast();
 
 // States
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState<string | null>(null);
 const [hotels, setHotels] = useState<HotelPreview[]>([]);
 const [filteredHotels, setFilteredHotels] = useState<HotelPreview[]>([]);
 const [isFilterSheetOpen, setIsFilterSheetOpen] = useState(false);
 const [sortBy, setSortBy] = useState<SortOptionType>(SORT_OPTIONS.RECOMMENDED);
 const [filtersApplied, setFiltersApplied] = useState(false);
 const [lastRefresh, setLastRefresh] = useState(new Date());
 const [filters, setFilters] = useState<Filters>(defaultFilters);

 // Search parameters
 const searchData = useMemo<SearchData>(() => {
   const parseDate = (dateStr: string | null): Date => {
     if (!dateStr) return new Date();
     const date = new Date(dateStr);
     return isNaN(date.getTime()) ? new Date() : date;
   };

   return {
     destination: searchParams.get('destination') || '',
     destinationCode: searchParams.get('destinationCode') || undefined,
     checkIn: parseDate(searchParams.get('checkIn')),
     checkOut: parseDate(searchParams.get('checkOut')),
     guests: parseInt(searchParams.get('guests') || '1', 10),
     preferences: searchParams.get('preferences') 
       ? JSON.parse(searchParams.get('preferences'))
       : currentUser?.preferences
   };
 }, [searchParams, currentUser?.preferences]);

// Load hotels
const fetchHotels = useCallback(async (shouldResetFilters = false) => {
    setLoading(true);
    setError(null);
 
    try {
      if (!searchData.destination) {
        throw new Error('Please specify a destination');
      }
 
      console.log('Searching hotels with params:', {
        destination: searchData.destinationCode || searchData.destination,
        checkIn: searchData.checkIn,
        checkOut: searchData.checkOut,
        guests: searchData.guests,
        preferences: searchData.preferences,
        filters: shouldResetFilters ? {} : filters
      });
 
      const result = await hotelService.searchHotels({
        destination: searchData.destinationCode || searchData.destination,
        checkIn: searchData.checkIn,
        checkOut: searchData.checkOut,
        guests: searchData.guests,
        preferences: searchData.preferences,
        filters: shouldResetFilters ? {} : filters
      });
 
      if (!result?.hotels) {
        throw new Error('Invalid response from hotel service');
      }
 
      setHotels(result.hotels);
      setFilteredHotels(result.hotels);
 
      if (result.hotels.length > 0 && shouldResetFilters) {
        const prices = result.hotels.map(hotel => hotel.price?.current?.amount || 0);
        const minPrice = Math.floor(Math.min(...prices));
        const maxPrice = Math.ceil(Math.max(...prices));
        setFilters(prev => ({
          ...prev,
          priceRange: [minPrice, maxPrice]
        }));
      }
 
      setLastRefresh(new Date());
    } catch (err) {
      console.error('Error fetching hotels:', err);
      setError(err instanceof Error ? err.message : 'Failed to load hotels');
      toast({
        title: "Error",
        description: err instanceof Error ? err.message : 'Failed to load hotels',
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  }, [searchData, filters, toast]);
 
  // Initial load
  useEffect(() => {
    if (searchData.destination) {
      console.log('Starting hotel search...');
      fetchHotels(true);
    }
  }, [searchData.destination, fetchHotels]);
 
  // Apply filters and sort
  const applyFiltersAndSort = useCallback(() => {
    if (!hotels.length) return;
 
    let filtered = hotels;
 
    // Apply price filter
    filtered = filtered.filter(hotel => {
      const price = hotel.price?.current?.amount || 0;
      return price >= filters.priceRange[0] && price <= filters.priceRange[1];
    });
 
    // Apply rating filter
    if (filters.rating > 0) {
      filtered = filtered.filter(hotel => (hotel.rating?.stars || 0) >= filters.rating);
    }
 
    // Apply feature filters
    const activeFeatures = Object.entries(filters.features)
      .filter(([_, isActive]) => isActive)
      .map(([feature]) => feature);
 
    if (activeFeatures.length > 0) {
      filtered = filtered.filter(hotel => 
        activeFeatures.every(feature => hotel.features?.includes(feature))
      );
    }
 
    // Apply distance filter
    if (filters.distance < 20) {
      filtered = filtered.filter(hotel => {
        const distance = parseFloat(hotel.location?.nearby?.cityCenter) || 0;
        return distance <= filters.distance;
      });
    }
 
    // Apply sort
    filtered = [...filtered].sort((a, b) => {
      switch (sortBy) {
        case SORT_OPTIONS.PRICE_LOW:
          return (a.price?.current?.amount || 0) - (b.price?.current?.amount || 0);
        case SORT_OPTIONS.PRICE_HIGH:
          return (b.price?.current?.amount || 0) - (a.price?.current?.amount || 0);
        case SORT_OPTIONS.RATING:
          return (b.rating?.stars || 0) - (a.rating?.stars || 0);
        case SORT_OPTIONS.DISTANCE:
          return (
            parseFloat(a.location?.nearby?.cityCenter) || 0) - 
            (parseFloat(b.location?.nearby?.cityCenter) || 0
          );
        case SORT_OPTIONS.RECOMMENDED:
        default:
          return (b.personalScore || 0) - (a.personalScore || 0);
      }
    });
 
    setFilteredHotels(filtered);
    setFiltersApplied(true);
  }, [hotels, filters, sortBy]);
 
  useEffect(() => {
    applyFiltersAndSort();
  }, [applyFiltersAndSort]);
 
  // Handlers
  const handleFilterChange = (key: keyof Filters, value: any) => {
    setFilters(prev => ({
      ...prev,
      [key]: value
    }));
  };
 
  const handleFilterReset = () => {
    setFilters(defaultFilters);
    setFiltersApplied(false);
  };
 
  const handleRefresh = () => {
    fetchHotels();
  };
 
 // Filter content component
 const FiltersContent: React.FC = () => (
    <div className="space-y-6">
      {/* Sort Section */}
      <div className="space-y-4">
        <Label>Sort By</Label>
        <Select 
          value={sortBy} 
          onValueChange={(value) => setSortBy(value as SortOptionType)}
        >
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={SORT_OPTIONS.RECOMMENDED}>
              <div className="flex items-center">
                <Star className="w-4 h-4 mr-2" />
                Recommended
              </div>
            </SelectItem>
            <SelectItem value={SORT_OPTIONS.PRICE_LOW}>
              <div className="flex items-center">
                <SortAsc className="w-4 h-4 mr-2" />
                Price: Low to High
              </div>
            </SelectItem>
            <SelectItem value={SORT_OPTIONS.PRICE_HIGH}>
              <div className="flex items-center">
                <SortAsc className="w-4 h-4 mr-2 rotate-180" />
                Price: High to Low
              </div>
            </SelectItem>
            <SelectItem value={SORT_OPTIONS.RATING}>
              <div className="flex items-center">
                <Star className="w-4 h-4 mr-2" />
                Rating
              </div>
            </SelectItem>
            <SelectItem value={SORT_OPTIONS.DISTANCE}>
              <div className="flex items-center">
                <MapPin className="w-4 h-4 mr-2" />
                Distance from Center
              </div>
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
 
      {/* Price Range Filter */}
      <div className="space-y-4">
        <Label>Price Range (per night)</Label>
        <Slider
          value={filters.priceRange}
          onValueChange={(value) => handleFilterChange('priceRange', value)}
          min={0}
          max={Math.max(...hotels.map(h => h.price?.current?.amount || 0))}
          step={10}
        />
        <div className="flex justify-between text-sm text-gray-600">
          <span>${filters.priceRange[0]}</span>
          <span>${filters.priceRange[1]}</span>
        </div>
      </div>
 
      {/* Rating Filter */}
      <div className="space-y-4">
        <Label>Minimum Rating</Label>
        <Slider
          value={[filters.rating]}
          onValueChange={(value) => handleFilterChange('rating', value[0])}
          min={0}
          max={5}
          step={0.5}
        />
        <div className="flex items-center text-sm text-gray-600">
          <Star className="w-4 h-4 mr-1" /> {filters.rating}+
        </div>
      </div>
 
      {/* Distance Filter */}
      <div className="space-y-4">
        <Label>Distance from City Center</Label>
        <Slider
          value={[filters.distance]}
          onValueChange={(value) => handleFilterChange('distance', value[0])}
          min={0}
          max={20}
          step={0.5}
        />
        <div className="text-sm text-gray-600">
          Up to {filters.distance} km
        </div>
      </div>
 
      {/* Features Filter */}
      <div className="space-y-4">
        <Label>Features</Label>
        <div className="space-y-4">
          {Object.entries(FEATURES_CONFIG).map(([key, config]) => {
            const Icon = config.icon;
            return (
              <div key={key} className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <Icon className="w-4 h-4 text-gray-500" />
                  <span className="text-sm">{config.name}</span>
                </div>
                <Switch
                  checked={filters.features[key] || false}
                  onCheckedChange={(checked) => 
                    handleFilterChange('features', { 
                      ...filters.features, 
                      [key]: checked 
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
 
 // Main render
 return (
    <div className="min-h-screen bg-gray-50">
      {/* Search Header */}
      <div className="bg-white border-b sticky top-0 z-30">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <SearchForm />
        </div>
      </div>
 
      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* Search Summary */}
        <div className="flex items-center justify-between mb-8">
          <div>
            <h1 className="text-2xl font-bold text-gray-900 mb-4">
              {filteredHotels.length} hotels in {searchData.destination}
            </h1>
            <div className="flex flex-wrap gap-4 text-sm text-gray-600">
              <div className="flex items-center gap-2">
                <Calendar className="w-4 h-4" />
                <span>{format(searchData.checkIn, 'PP')} - {format(searchData.checkOut, 'PP')}</span>
              </div>
              <div className="flex items-center gap-2">
                <Users className="w-4 h-4" />
                <span>{searchData.guests} guests</span>
              </div>
              <div className="flex items-center gap-2">
                <Timer className="w-4 h-4" />
                <span>Last updated: {format(lastRefresh, 'HH:mm')}</span>
              </div>
            </div>
          </div>
          <Button
            variant="outline"
            size="sm"
            onClick={handleRefresh}
            disabled={loading}
          >
            {loading ? (
              <Loader2 className="w-4 h-4 animate-spin" />
            ) : (
              'Refresh'
            )}
          </Button>
        </div>
 
        {/* Filters and Results */}
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Desktop Filters */}
          <div className="hidden lg:block w-64">
            <div className="sticky top-28">
              <Card>
                <CardContent className="p-6">
                  <div className="flex items-center justify-between mb-6">
                    <h3 className="font-semibold">Filters</h3>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={handleFilterReset}
                      disabled={!filtersApplied}
                    >
                      Reset
                    </Button>
                  </div>
                  <FiltersContent />
                </CardContent>
              </Card>
            </div>
          </div>
 
          {/* Mobile Filters */}
          <Sheet open={isFilterSheetOpen} onOpenChange={setIsFilterSheetOpen}>
            <SheetTrigger asChild>
              <Button variant="outline" className="lg:hidden">
                <Filter className="w-4 h-4 mr-2" /> 
                {filtersApplied ? 'Filters Applied' : 'Filters'}
              </Button>
            </SheetTrigger>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>Filters</SheetTitle>
                <SheetDescription>
                  Adjust your search filters
                </SheetDescription>
              </SheetHeader>
              <div className="mt-6">
                <FiltersContent />
              </div>
              <SheetFooter className="mt-6">
                <Button
                  variant="outline"
                  onClick={handleFilterReset}
                >
                  Reset Filters
                </Button>
              </SheetFooter>
            </SheetContent>
          </Sheet>
 
          {/* Results Grid */}
          <div className="flex-1">
            {error ? (
              <Card className="p-6 text-center">
                <div className="text-red-500 mb-4">{error}</div>
                <Button onClick={() => fetchHotels(true)}>Try Again</Button>
              </Card>
            ) : filteredHotels.length === 0 ? (
              <Card className="p-6">
                <div className="text-center py-8">
                  <div className="mb-4 text-gray-400">
                    <Search className="w-12 h-12 mx-auto mb-4" />
                    <h3 className="text-lg font-semibold">No hotels found</h3>
                  </div>
                  <p className="text-gray-600 mb-6">
                    Try adjusting your search criteria or filters
                  </p>
                  <Button onClick={handleFilterReset}>Reset Filters</Button>
                </div>
              </Card>
            ) : (
              <>
                {loading && (
                  <div className="absolute top-4 right-4 bg-white rounded-full p-2 shadow-lg">
                    <Loader2 className="w-4 h-4 animate-spin" />
                  </div>
                )}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-6">
                  {filteredHotels.map((hotel) => (
                    <HotelCard 
                      key={hotel.id}
                      hotel={hotel}
                      searchParams={{
                        checkIn: searchData.checkIn,
                        checkOut: searchData.checkOut,
                        guests: searchData.guests
                      }}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
 };
 
 export default SearchResultsPage;