// src/components/ui/logo.tsx
import React from 'react';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';

interface LogoProps extends React.SVGProps<SVGSVGElement> {
 className?: string;
 animate?: boolean;
 variant?: 'light' | 'dark';
}

const Logo: React.FC<LogoProps> = ({ 
 className, 
 animate = false,
 variant = 'dark',
 ...props 
}) => {
 const pathVariants = {
   hidden: { pathLength: 0, opacity: 0 },
   visible: (i: number) => ({
     pathLength: 1,
     opacity: 1,
     transition: {
       pathLength: { 
         duration: 2,
         delay: i * 0.1,
         ease: "easeInOut" 
       },
       opacity: { 
         duration: 0.5,
         delay: i * 0.1 
       }
     }
   })
 };

 const Component = animate ? motion.svg : 'svg';
 const fillColor = variant === 'light' ? 'white' : 'currentColor';

 return (
   <Component
     viewBox="0 0 2217.29 413.53"
     className={cn(
       "h-8 w-auto transition-colors duration-300",
       variant === 'light' ? 'text-white' : 'text-gray-900',
       className
     )}
     xmlns="http://www.w3.org/2000/svg"
     {...props}
   >
     <g>
       {animate ? (
         <>
           <motion.polygon 
             variants={pathVariants}
             initial="hidden"
             animate="visible"
             custom={0}
             fill={fillColor}
             points="569.72,177.83 513.68,177.83 513.68,110.08 476.09,110.08 476.09,282.71 513.68,282.71 513.68,210.28 569.72,210.28 569.72,282.71 607.31,282.71 607.31,110.08 569.72,110.08" 
           />
           <motion.path 
             variants={pathVariants}
             initial="hidden"
             animate="visible"
             custom={1}
             fill={fillColor}
             d="M713.64,107.38c-44.61,0-74.57,35.82-74.57,89.14s29.97,89.14,74.57,89.14s74.58-35.82,74.58-89.14 S758.25,107.38,713.64,107.38z M713.64,252.96c-26.91,0-36.46-30.4-36.46-56.44c0-34.44,14.31-56.69,36.46-56.69 c22.15,0,36.46,22.25,36.46,56.69C750.1,222.56,740.55,252.96,713.64,252.96z"
           />
           <motion.polygon 
             variants={pathVariants}
             initial="hidden"
             animate="visible"
             custom={2}
             fill={fillColor}
             points="809.44,142.29 849.79,142.29 849.79,282.71 887.12,282.71 887.12,142.29 927.47,142.29 927.47,110.08 809.44,110.08" 
           />
           <motion.polygon 
             variants={pathVariants}
             initial="hidden"
             animate="visible"
             custom={3}
             fill={fillColor}
             points="955.28,282.71 1059.85,282.71 1059.85,250.25 992.87,250.25 992.87,211.02 1058.01,211.02 1058.01,178.57 992.87,178.57 992.87,142.29 1059.85,142.29 1059.85,110.08 955.28,110.08" 
           />
           <motion.polygon 
             variants={pathVariants}
             initial="hidden"
             animate="visible"
             custom={4}
             fill={fillColor}
             points="1130.53,110.08 1092.94,110.08 1092.94,282.71 1191.45,282.71 1191.45,250.25 1130.53,250.25" 
           />
           <motion.path 
             variants={pathVariants}
             initial="hidden"
             animate="visible"
             custom={5}
             fill={fillColor}
             d="M1280.58,177.08c-14.85-6.23-27.68-11.61-27.68-21.2c0-9.6,7.79-15.8,19.84-15.8c13.42,0,25.3,4.47,34.35,12.92l3.53,3.29 l19.05-27.68l-2.73-2.44c-13.74-12.3-32.21-18.8-53.41-18.8c-34.04,0-58.75,21.02-58.75,49.98c0,33.41,27.2,44.66,49.06,53.7 c15.99,6.61,29.79,12.32,29.79,24.39c0,10.64-9.13,17.52-23.27,17.52c-14.69,0-29.24-6.14-38.9-16.43l-3.51-3.73l-19.7,28.26 l2.48,2.46c14.61,14.49,35.23,22.14,59.63,22.14c37.29,0,61.39-20.58,61.39-52.44C1331.75,198.54,1303.38,186.64,1280.58,177.08z"
           />
           <motion.path 
             variants={pathVariants}
             initial="hidden"
             animate="visible"
             custom={6}
             fill={fillColor}
             d="M1482.07,164.99c0-32.33-22.75-54.9-55.32-54.9h-64.82v172.62h37.59v-62.08h14.85l29.8,62.08h42.1l-34.88-68.43 C1467.02,208.36,1482.07,192.9,1482.07,164.99z M1399.53,142.29h23.01c13.22,0,21.43,8.79,21.43,22.94 c0,14.15-8.21,22.94-21.43,22.94h-23.01V142.29z"
           />
           <motion.path 
             variants={pathVariants}
             initial="hidden"
             animate="visible"
             custom={7}
             fill={fillColor}
             d="M1552.94,110.08l-55.14,172.62h40.46l9.49-32.52h51.52l9.49,32.52h40.46l-55.14-172.62H1552.94z M1591.05,217.73h-34.8 l17.4-59.59L1591.05,217.73z"
           />
           <motion.polygon 
             variants={pathVariants}
             initial="hidden"
             animate="visible"
             custom={8}
             fill={fillColor}
             points="1659.42,142.29 1699.77,142.29 1699.77,282.71 1737.09,282.71 1737.09,142.29 1777.45,142.29 1777.45,110.08 1659.42,110.08" 
           />
           <motion.rect 
             variants={pathVariants}
             initial="hidden"
             animate="visible"
             custom={9}
             fill={fillColor}
             x="1805.26" 
             y="110.08" 
             width="37.59" 
             height="172.62"
           />
           <motion.polygon 
             variants={pathVariants}
             initial="hidden"
             animate="visible"
             custom={10}
             fill={fillColor}
             points="1974.57,202.78 1917.49,110.08 1881.47,110.08 1881.47,282.71 1919.06,282.71 1919.06,187.1 1977.72,282.71 2012.16,282.71 2012.16,110.08 1974.57,110.08" 
           />
           <motion.path 
             variants={pathVariants}
             initial="hidden"
             animate="visible"
             custom={11}
             fill={fillColor}
             d="M2108.28,219.15h36.92v24.66c-5.06,4.73-13.96,9.39-25.12,9.39c-22.95,0-37.78-22.25-37.78-56.69s14.83-56.69,37.78-56.69 c15.33,0,23.96,10.31,28.49,18.96l1.8,3.43l31.37-13.67l-2.06-3.95c-12.7-24.35-33.31-37.22-59.6-37.22 c-45.39,0-75.89,35.82-75.89,89.14c0,53.47,30.5,89.39,75.89,89.39c23.86,0,44.07-9,60.06-26.75l1.07-1.19v-71.03h-72.93V219.15z"
           />
         </>
       ) : (
         <>
           <polygon fill={fillColor} points="569.72,177.83 513.68,177.83 513.68,110.08 476.09,110.08 476.09,282.71 513.68,282.71 513.68,210.28 569.72,210.28 569.72,282.71 607.31,282.71 607.31,110.08 569.72,110.08" />
           <path fill={fillColor} d="M713.64,107.38c-44.61,0-74.57,35.82-74.57,89.14s29.97,89.14,74.57,89.14s74.58-35.82,74.58-89.14 S758.25,107.38,713.64,107.38z M713.64,252.96c-26.91,0-36.46-30.4-36.46-56.44c0-34.44,14.31-56.69,36.46-56.69 c22.15,0,36.46,22.25,36.46,56.69C750.1,222.56,740.55,252.96,713.64,252.96z"/>
           <polygon fill={fillColor} points="809.44,142.29 849.79,142.29 849.79,282.71 887.12,282.71 887.12,142.29 927.47,142.29 927.47,110.08 809.44,110.08" />
           <polygon fill={fillColor} points="955.28,282.71 1059.85,282.71 1059.85,250.25 992.87,250.25 992.87,211.02 1058.01,211.02 1058.01,178.57 992.87,178.57 992.87,142.29 1059.85,142.29 1059.85,110.08 955.28,110.08" />
           <polygon fill={fillColor} points="1130.53,110.08 1092.94,110.08 1092.94,282.71 1191.45,282.71 1191.45,250.25 1130.53,250.25" />
           <path fill={fillColor} d="M1280.58,177.08c-14.85-6.23-27.68-11.61-27.68-21.2c0-9.6,7.79-15.8,19.84-15.8c13.42,0,25.3,4.47,34.35,12.92l3.53,3.29 l19.05-27.68l-2.73-2.44c-13.74-12.3-32.21-18.8-53.41-18.8c-34.04,0-58.75,21.02-58.75,49.98c0,33.41,27.2,44.66,49.06,53.7 c15.99,6.61,29.79,12.32,29.79,24.39c0,10.64-9.13,17.52-23.27,17.52c-14.69,0-29.24-6.14-38.9-16.43l-3.51-3.73l-19.7,28.26 l2.48,2.46c14.61,14.49,35.23,22.14,59.63,22.14c37.29,0,61.39-20.58,61.39-52.44C1331.75,198.54,1303.38,186.64,1280.58,177.08z"/>
           <path fill={fillColor} d="M1482.07,164.99c0-32.33-22.75-54.9-55.32-54.9h-64.82v172.62h37.59v-62.08h14.85l29.8,62.08h42.1l-34.88-68.43 C1467.02,208.36,1482.07,192.9,1482.07,164.99z M1399.53,142.29h23.01c13.22,0,21.43,8.79,21.43,22.94 c0,14.15-8.21,22.94-21.43,22.94h-23.01V142.29z"/>
           <path fill={fillColor} d="M1552.94,110.08l-55.14,172.62h40.46l9.49-32.52h51.52l9.49,32.52h40.46l-55.14-172.62H1552.94z M1591.05,217.73h-34.8 l17.4-59.59L1591.05,217.73z"/>
           <polygon fill={fillColor} points="1659.42,142.29 1699.77,142.29 1699.77,282.71 1737.09,282.71 1737.09,142.29 1777.45,142.29 1777.45,110.08 1659.42,110.08" />
           <rect fill={fillColor} x="1805.26" y="110.08" width="37.59" height="172.62"/>
           <polygon fill={fillColor} points="1974.57,202.78 1917.49,110.08 1881.47,110.08 1881.47,282.71 1919.06,282.71 1919.06,187.1 1977.72,282.71 2012.16,282.71 2012.16,110.08 1974.57,110.08" />
           <path fill={fillColor} d="M2108.28,219.15h36.92v24.66c-5.06,4.73-13.96,9.39-25.12,9.39c-22.95,0-37.78-22.25-37.78-56.69s14.83-56.69,37.78-56.69 c15.33,0,23.96,10.31,28.49,18.96l1.8,3.43l31.37-13.67l-2.06-3.95c-12.7-24.35-33.31-37.22-59.6-37.22 c-45.39,0-75.89,35.82-75.89,89.14c0,53.47,30.5,89.39,75.89,89.39c23.86,0,44.07-9,60.06-26.75l1.07-1.19v-71.03h-72.93V219.15z"/>
         </>
       )}
     </g>
     <g>
       {animate ? (
         <motion.path 
           variants={pathVariants}
           initial="hidden"
           animate="visible"
           custom={12}
           fill={fillColor}
           d="M213.48,384.63c-98.08,0-177.87-79.79-177.87-177.87S115.4,28.9,213.48,28.9s177.87,79.79,177.87,177.86 S311.55,384.63,213.48,384.63z M213.48,55.16c-83.6,0-151.61,68.01-151.61,151.6s68.01,151.61,151.61,151.61 s151.61-68.01,151.61-151.61S297.07,55.16,213.48,55.16z"
         />
       ) : (
         <path 
           fill={fillColor} 
           d="M213.48,384.63c-98.08,0-177.87-79.79-177.87-177.87S115.4,28.9,213.48,28.9s177.87,79.79,177.87,177.86 S311.55,384.63,213.48,384.63z M213.48,55.16c-83.6,0-151.61,68.01-151.61,151.6s68.01,151.61,151.61,151.61 s151.61-68.01,151.61-151.61S297.07,55.16,213.48,55.16z"
         />
       )}
     </g>
     <g>
       {animate ? (
         <motion.path 
           variants={pathVariants}
           initial="hidden"
           animate="visible"
           custom={13}
           fill={fillColor}
           d="M277.25,277.01h-42.78v-54.88h-43.76v54.88h-42.78V138.54h42.78v51.92h43.76v-51.92h42.78V277.01z"
         />
       ) : (
         <path 
           fill={fillColor}
           d="M277.25,277.01h-42.78v-54.88h-43.76v54.88h-42.78V138.54h42.78v51.92h43.76v-51.92h42.78V277.01z"
         />
       )}
     </g>
   </Component>
 );
};

export default Logo;