// src/pages/hotel/HotelDetailPage.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useToast } from '../../hooks/useToast';
import { Button } from '../../components/ui/button';
import hotelService from '../../services/api/hotels';
import { ROUTES } from '../../config/paths';

import {
  HeaderInfo,
  MainContent,
  Sidebar,
  LoadingState,
} from '../../components/hotels/detail';

const HotelDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { toast } = useToast();

  const [hotel, setHotel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const searchData = {
    checkIn: searchParams.get('checkIn'),
    checkOut: searchParams.get('checkOut'),
    adults: parseInt(searchParams.get('adults'), 10) || 2,
    children: parseInt(searchParams.get('children'), 10) || 0
  };

  const loadHotelDetails = async () => {
    if (!id) return;
    
    try {
      setLoading(true);
      setError(null);
      
      console.log('Loading hotel details for ID:', id);
      const data = await hotelService.getHotelDetails(id, searchData);
      
      if (!data) {
        throw new Error('Hotel not found');
      }

      console.log('Hotel data loaded:', data);
      setHotel(data);
    } catch (err) {
      console.error('Error fetching hotel details:', err);
      setError(err.message);
      toast({
        title: "Error",
        description: "Unable to load hotel details",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadHotelDetails();
  }, [id]);

  const handleBookingSubmit = async (bookingData) => {
    try {
      console.log('Booking data received:', bookingData);
      console.log('Available rooms:', hotel.details.roomTypes);
  
      if (!hotel) {
        throw new Error('Hotel details not available');
      }
  
      // Изменим логику поиска комнаты
      const roomIdParts = bookingData.roomId.split('_');
      const roomIndex = parseInt(roomIdParts[2]); // Получаем индекс из ID
      const selectedRoom = hotel.details.roomTypes[roomIndex];
  
      console.log('Room index:', roomIndex);
      console.log('Selected room:', selectedRoom);
  
      if (!selectedRoom) {
        throw new Error('Selected room not found');
      }
  
      const bookingParams = new URLSearchParams({
        hotelId: hotel.id,
        roomId: bookingData.roomId, // Используем полный ID комнаты
        checkIn: bookingData.checkIn.toISOString().split('T')[0],
        checkOut: bookingData.checkOut.toISOString().split('T')[0],
        adults: bookingData.guests.adults.toString(),
        children: bookingData.guests.children.toString(),
        price: selectedRoom.price.toString() // Используем цену из найденной комнаты
      });
  
      console.log('Navigation params:', Object.fromEntries(bookingParams));
      navigate(`${ROUTES.BOOKING}?${bookingParams.toString()}`);
  
    } catch (error) {
      console.error('Booking error:', error);
      toast({
        title: "Booking Error",
        description: error.message,
        variant: "destructive"
      });
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return <LoadingState />;
  }

  if (error || !hotel) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-900 mb-2">
            {error || 'Hotel not found'}
          </h2>
          <Button onClick={handleBack} variant="outline">
            Go Back
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Шапка с основной информацией об отеле */}
      <HeaderInfo hotel={hotel} onBack={handleBack} />
      
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Основной контент с информацией об отеле */}
          <div className="lg:col-span-2">
            <MainContent 
              hotel={hotel}
              searchData={searchData}
            />
          </div>

          {/* Сайдбар с формой бронирования */}
          <div>
            <Sidebar 
              hotel={hotel}
              onBookingSubmit={handleBookingSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelDetailPage;