// src/components/PitchDeck/components/slides/TitleSlide.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { Slide } from '../Slide';

export const TitleSlide: React.FC = () => (
  <Slide className="bg-gradient-to-br from-gray-900 to-gray-800 text-white" fullscreen>
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="text-center"
    >
      <h1 className="text-6xl font-bold mb-6">HotelsRating.com</h1>
      <p className="text-2xl mb-4">Revolutionizing Hotel Discovery Through AI</p>
      <p className="text-xl opacity-75">Investment Pitch Deck 2025</p>
    </motion.div>
  </Slide>
);