// src/services/api/hotels/index.ts
import { HotelService } from './HotelService'; 

// Создаем единственный экземпляр
const hotelService = new HotelService();

// Предотвращаем модификацию экземпляра
Object.freeze(hotelService);

// Экспортируем класс для тестирования
export { HotelService };

// Экспортируем экземпляр как default
export default hotelService;