// src/components/contact/ContactFormSidebar.tsx
import React from 'react';
import { Mail, MapPin } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';

const ContactFormSidebar: React.FC = () => {
  return (
    <div className="space-y-6">
      <Card>
        <CardContent className="p-6 space-y-6">
          <h3 className="font-semibold text-lg">Contact Information</h3>

          <div className="space-y-4">
            <div className="flex items-center gap-3">
              <Mail className="w-5 h-5 text-gray-900" />
              <div>
                <h4 className="font-medium">Email</h4>
                <p className="text-sm text-gray-600">
                  contact@hotelsrating.com
                </p>
              </div>
            </div>

            <div className="flex items-center gap-3">
              <MapPin className="w-5 h-5 text-gray-900" />
              <div>
                <h4 className="font-medium">Location</h4>
                <p className="text-sm text-gray-600">
                  Dubai, United Arab Emirates
                </p>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ContactFormSidebar;