// src/components/PitchDeck/components/slides/RevenueSlide.tsx
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Slide } from '../Slide';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';

const revenueStreams = [
  { 
    title: 'Booking Commissions', 
    description: 'Direct integrations with Amadeus',
    value: '15-25%'
  },
  { 
    title: 'Hotel Subscriptions', 
    description: 'Premium features for properties',
    value: '$299-999/mo'
  },
  { 
    title: 'Advertising', 
    description: 'Targeted promotion opportunities',
    value: 'CPC/CPM'
  },
  { 
    title: 'Professional Services', 
    description: 'Video production & analytics',
    value: 'Custom'
  }
];

const revenueData = [
  { year: 'Year 1', value: 0.5 },
  { year: 'Year 2', value: 3 },
  { year: 'Year 3', value: 8 }
];

const metrics = [
  {
    value: '$500k',
    label: 'Monthly Recurring Revenue'
  },
  {
    value: '85%',
    label: 'Gross Margin'
  },
  {
    value: '24mo',
    label: 'Runway'
  }
];

export const RevenueSlide: React.FC = () => (
  <Slide title="Revenue Model">
    <div className="space-y-8">
      <div className="grid md:grid-cols-2 gap-8">
        <Card>
          <CardContent className="p-6">
            <h3 className="text-xl font-bold mb-4">Revenue Streams</h3>
            <div className="space-y-4">
              {revenueStreams.map((stream, index) => (
                <div key={index} className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex justify-between items-center mb-2">
                    <h4 className="font-semibold">{stream.title}</h4>
                    <span className="text-primary-600">{stream.value}</span>
                  </div>
                  <p className="text-gray-600">{stream.description}</p>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="p-6">
            <h3 className="text-xl font-bold mb-4">Revenue Projections</h3>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={revenueData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" fill="#0ea5e9" name="Revenue ($M)" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-bold mb-4">Key Metrics</h3>
          <div className="grid md:grid-cols-3 gap-6">
            {metrics.map((metric, index) => (
              <div key={index} className="text-center">
                <div className="text-3xl font-bold text-primary-600">
                  {metric.value}
                </div>
                <div className="text-sm text-gray-600">
                  {metric.label}
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-bold mb-4">Growth Strategy</h3>
          <div className="grid md:grid-cols-4 gap-6">
            {[
              {
                phase: 'Phase 1',
                title: 'Market Entry',
                metric: '10K',
                label: 'Users'
              },
              {
                phase: 'Phase 2',
                title: 'Expansion',
                metric: '50K',
                label: 'Users'
              },
              {
                phase: 'Phase 3',
                title: 'Scaling',
                metric: '200K',
                label: 'Users'
              },
              {
                phase: 'Phase 4',
                title: 'Global',
                metric: '1M+',
                label: 'Users'
              }
            ].map((phase, index) => (
              <div key={index} className="text-center p-4 bg-gray-50 rounded-lg">
                <div className="text-sm text-primary-600 mb-1">{phase.phase}</div>
                <div className="font-semibold mb-2">{phase.title}</div>
                <div className="text-2xl font-bold text-gray-900 mb-1">
                  {phase.metric}
                </div>
                <div className="text-sm text-gray-600">{phase.label}</div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  </Slide>
);