// src/pages/contact/ContactPage.tsx
import React from 'react';
import ContactFormLayout from '@/components/contact/ContactFormLayout';
import { useAuth } from '@/contexts/AuthContext';
import { Helmet } from 'react-helmet-async';

const ContactPage: React.FC = () => {
  const { currentUser } = useAuth();

  return (
    <>
      <Helmet>
        <title>Contact Us - HotelsRating</title>
        <meta name="description" content="Contact HotelsRating team for any inquiries or support" />
      </Helmet>
      <ContactFormLayout 
        initialData={{
          name: currentUser?.displayName || '',
          email: currentUser?.email || ''
        }}
      />
    </>
  );
};

export default ContactPage;