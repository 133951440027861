// src/App.tsx
import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { HelmetProvider } from 'react-helmet-async';
import { Analytics } from './components/analytics';
import { ROUTES } from './config/paths';
import { ToastContainer } from 'react-toastify';

// Layout Components
import Navigation from './components/layout/Navigation';
import Footer from './components/layout/Footer';
import ComingSoon from './components/shared/ComingSoon';
import NotFound from './components/shared/NotFound';
import PrivateRoute from './components/auth/PrivateRoute';
import PrivateAdminRoute from './components/auth/PrivateAdminRoute';
import BetaConsent from './components/shared/BetaConsent';

// Core Pages
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import ProfilePage from './pages/profile/ProfilePage';
import SearchResultsPage from './pages/search/SearchResultsPage';
import HotelDetailPage from './pages/hotel/HotelDetailPage';
import ContactPage from './pages/contact/ContactPage';
import CategoryPage from './pages/category/CategoryPage';
import PersonalizationPage from './pages/PersonalizationPage';

// Booking Pages
import BookingPage from './pages/booking/BookingPage';
import BookingConfirmationPage from './pages/booking/BookingConfirmationPage';
import BookingHistoryPage from './pages/booking/BookingHistoryPage';

// Admin Pages
import AdminDashboardPage from './pages/admin/AdminDashboardPage';
import AdminContactPanel from './pages/admin/contact/AdminContactPanel';
import AdminBookingsPage from './pages/admin/bookings/AdminBookingsPage';
import AdminBookingDetailsPage from './pages/admin/bookings/AdminBookingDetailsPage';
import AdminHotelsPage from './pages/admin/hotels/AdminHotelsPage';
import { AdminPitchDeck } from '@/components/admin';

// Legal Pages
import PrivacyPolicyPage from './pages/legal/PrivacyPolicyPage';
import TermsOfServicePage from './pages/legal/TermsOfServicePage';
import CookiePolicyPage from './pages/legal/CookiePolicyPage';
import DisclaimerPage from './pages/legal/DisclaimerPage';
import AffiliateDisclosurePage from './pages/legal/AffiliateDisclosurePage';
import CommentPolicyPage from './pages/legal/CommentPolicyPage';
import RefundPolicyPage from './pages/legal/RefundPolicyPage';
import BetaTermsPage from './pages/legal/BetaTermsPage';

// Information Pages
import ForTravelersPage from './pages/information/ForTravelersPage';
import ForHotelsPage from './pages/information/ForHotelsPage';
import ForBloggersPage from './pages/information/ForBloggersPage';
import ForInvestorsPage from './pages/information/ForInvestorsPage';

// Support Pages
import HelpCenterPage from './pages/support/HelpCenterPage';

// Development Components
import TestFirebase from './components/TestFirebase';
import TestAmadeusApi from './components/TestAmadeusApi';

// Styles
import 'react-toastify/dist/ReactToastify.css';

// Layout wrapper component
const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/';

  return (
    <div className="flex flex-col min-h-screen">
      {!isLandingPage && <Navigation />}
      <main className={`flex-grow ${!isLandingPage ? 'bg-gray-50' : ''}`}>
        {children}
      </main>
      <Footer />
    </div>
  );
};

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <GoogleReCaptchaProvider
        reCaptchaKey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
        scriptProps={{
          async: true,
          defer: true,
          appendTo: 'head',
        }}
      >
        <AuthProvider>
          <BrowserRouter>
            <Analytics />
            <MainLayout>
              <Routes>
              {/* Public Routes */}
              <Route path={ROUTES.HOME} element={<LandingPage />} />
              <Route path={ROUTES.LOGIN} element={<LoginPage />} />
              <Route path={ROUTES.REGISTER} element={<RegisterPage />} />
              <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
              <Route path={ROUTES.SEARCH} element={<SearchResultsPage />} />
              <Route path={ROUTES.HOTEL_DETAIL} element={<HotelDetailPage />} />
              <Route path={ROUTES.HOTEL_CATEGORY} element={<CategoryPage />} />
              <Route path={ROUTES.CONTACT} element={<ContactPage />} />

              {/* Booking Routes */}
              <Route
                path={ROUTES.BOOKING}
                element={
                  <PrivateRoute>
                    <BookingPage />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.BOOKING_CONFIRMATION}
                element={
                  <PrivateRoute>
                    <BookingConfirmationPage />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.BOOKING_HISTORY}
                element={
                  <PrivateRoute>
                    <BookingHistoryPage />
                  </PrivateRoute>
                }
              />

              {/* Admin Routes */}
              <Route
                path={ROUTES.ADMIN_HOTELS}
                element={
                  <PrivateAdminRoute>
                    <AdminHotelsPage />
                  </PrivateAdminRoute>
                }
              />
              <Route 
                path="/admin/pitch-deck" 
                element={
                  <PrivateAdminRoute>
                    <AdminPitchDeck />
                  </PrivateAdminRoute>
                } 
              />
              <Route 
                path="/admin" 
                element={
                  <PrivateAdminRoute>
                    <AdminDashboardPage />
                  </PrivateAdminRoute>
                } 
              />

              {/* Protected Routes */}
              <Route
                path={ROUTES.PROFILE}
                element={
                  <PrivateRoute>
                    <ProfilePage />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.RATING}
                element={
                  <PrivateRoute>
                    <PersonalizationPage />
                  </PrivateRoute>
                }
              />

              {/* Admin contact routes */}
              <Route
                path={`${ROUTES.ADMIN_CONTACT}/*`}
                element={
                  <PrivateAdminRoute>
                    <AdminContactPanel />
                  </PrivateAdminRoute>
                }
              />

              {/* Legal Pages */}
              <Route path={ROUTES.PRIVACY} element={<PrivacyPolicyPage />} />
              <Route path={ROUTES.TERMS} element={<TermsOfServicePage />} />
              <Route path={ROUTES.COOKIES} element={<CookiePolicyPage />} />
              <Route path={ROUTES.DISCLAIMER} element={<DisclaimerPage />} />
              <Route path={ROUTES.AFFILIATE_DISCLOSURE} element={<AffiliateDisclosurePage />} />
              <Route path={ROUTES.COMMENT_POLICY} element={<CommentPolicyPage />} />
              <Route path={ROUTES.REFUND_POLICY} element={<RefundPolicyPage />} />
              <Route path={ROUTES.BETA_TERMS} element={<BetaTermsPage />} />

              {/* Information Pages */}
              <Route path={ROUTES.FOR_TRAVELERS} element={<ForTravelersPage />} />
              <Route path={ROUTES.FOR_HOTELS} element={<ForHotelsPage />} />
              <Route path={ROUTES.FOR_BLOGGERS} element={<ForBloggersPage />} />
              <Route path={ROUTES.FOR_INVESTORS} element={<ForInvestorsPage />} />

              {/* Support Pages */}
              <Route path={ROUTES.HELP} element={<HelpCenterPage />} />

              {/* Development Routes */}
              {import.meta.env.DEV && (
                <>
                  <Route path={ROUTES.TEST_FIREBASE} element={<TestFirebase />} />
                  <Route path={ROUTES.TEST_AMADEUS} element={<TestAmadeusApi />} />
                </>
              )}

              {/* 404 Page */}
              <Route path="*" element={<NotFound />} />
              </Routes>
            </MainLayout>
            <BetaConsent />
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </BrowserRouter>
        </AuthProvider>
      </GoogleReCaptchaProvider>
    </HelmetProvider>
  );
};

export default App;