// src/components/admin/pitch-deck/index.tsx
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { useAuth } from '@/contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Download } from 'lucide-react';
import { ROUTES } from '@/config/paths';

// Import slides
import { TitleSlide } from './components/slides/TitleSlide';
import { ProblemSlide } from './components/slides/ProblemSlide';
import { SolutionSlide } from './components/slides/SolutionSlide';
import { MarketSlide } from './components/slides/MarketSlide';
import { CompetitiveSlide } from './components/slides/CompetitiveSlide';
import { RevenueSlide } from './components/slides/RevenueSlide';
import { FundingSlide } from './components/slides/FundingSlide';
import { TractionSlide } from './components/slides/TractionSlide';
import { TeamSlide } from './components/slides/TeamSlide';
import { ContactSlide } from './components/slides/ContactSlide';

const AdminPitchDeck: React.FC = () => {
  const { currentUser } = useAuth();
  const componentRef = useRef<HTMLDivElement>(null);

  // Protect access
  if (!currentUser?.isAdmin) {
    return <Navigate to={ROUTES.HOME} replace />;
  }

  const PrintComponent = () => (
    <Button className="bg-white text-gray-900 hover:bg-gray-100">
      <Download className="mr-2 h-5 w-5" />
      Download Pitch Deck
    </Button>
  );

  return (
    <div className="bg-white">
      <ReactToPrint
        trigger={() => (
          <Button 
            className="fixed top-4 right-4 z-50 no-print"
          >
            <Download className="mr-2 h-4 w-4" />
            Export PDF
          </Button>
        )}
        content={() => componentRef.current}
        documentTitle="HotelsRating_Pitch_Deck_2025"
        pageStyle={`
          @page {
            size: A4 landscape;
            margin: 0;
          }
          @media print {
            html, body {
              height: 100vh;
              margin: 0 !important;
              padding: 0 !important;
              overflow: hidden;
            }
            .no-print {
              display: none !important;
            }
            .slide {
              page-break-after: always;
              page-break-inside: avoid;
              height: 100vh;
            }
            * {
              -webkit-print-color-adjust: exact !important;
              print-color-adjust: exact !important;
            }
          }
        `}
      />

      <div ref={componentRef} className="print-content">
        <TitleSlide />
        <ProblemSlide />
        <SolutionSlide />
        <MarketSlide />
        <CompetitiveSlide />
        <RevenueSlide />
        <FundingSlide />
        <TractionSlide />
        <TeamSlide />
        <ContactSlide 
          printTrigger={PrintComponent}
        />
      </div>
    </div>
  );
};

export default AdminPitchDeck;