// src/config/categories.ts

import { CategoryGroup, CategoryId, HotelCategory } from '../types/hotelCategories';

export const HOTEL_CATEGORIES: Record<CategoryId, HotelCategory> = {
 // Popular Travel Styles
 leisure_travel: {
   id: 'leisure_travel',
   name: 'Leisure Travel',
   description: 'Relaxing stays for the perfect vacation',
   imageUrl: '/images/presets/leisure-travel.webp',
   featured: true,
   groupId: 'popular_travel',
   priority: 1,
   criteria: {
     minRating: 4,
     requiredAmenities: ['pool', 'spa', 'restaurant']
   }
 },

 business_travel: {
   id: 'business_travel', 
   name: 'Business Travel',
   description: 'Perfect for business travelers',
   imageUrl: '/images/presets/business-travel.webp',
   featured: true,
   groupId: 'popular_travel',
   priority: 2,
   criteria: {
     requiredAmenities: ['wifi', 'desk', 'business-center'],
     locationTypes: ['city-center', 'near-airport']
   }
 },

 family_travel: {
   id: 'family_travel',
   name: 'Family Travel',
   description: 'Family-friendly accommodations',
   imageUrl: '/images/presets/family-travel.webp',
   featured: true,
   groupId: 'popular_travel',
   priority: 3,
   criteria: {
     requiredAmenities: ['children-activities', 'family-rooms', 'kids-pool']
   }
 },

 romantic_getaways: {
   id: 'romantic_getaways',
   name: 'Romantic Getaways',
   description: 'Perfect settings for couples',
   imageUrl: '/images/presets/romantic-getaway.webp',
   featured: true,
   groupId: 'popular_travel',
   priority: 4,
   criteria: {
     minRating: 4.5,
     requiredAmenities: ['spa', 'fine-dining', 'room-service']
   }
 },

 adventure_travel: {
   id: 'adventure_travel',
   name: 'Adventure Travel',
   description: 'For thrill-seekers and explorers',
   imageUrl: '/images/presets/adventure-travel.webp',
   featured: false,
   groupId: 'popular_travel',
   priority: 5,
   criteria: {
     locationTypes: ['mountain', 'forest', 'remote']
   }
 },

 luxury_experience: {
   id: 'luxury_experience',
   name: 'Luxury Experience',
   description: 'Ultimate luxury accommodations',
   imageUrl: '/images/presets/luxury-experience.webp',
   featured: true,
   groupId: 'popular_travel',
   priority: 6,
   criteria: {
     minRating: 4.8,
     priceRange: { min: 500 }
   }
 },

 party_nightlife: {
   id: 'party_nightlife',
   name: 'Party & Nightlife',
   description: 'Best locations for nightlife enthusiasts',
   imageUrl: '/images/presets/party-nightlife.webp',
   featured: false,
   groupId: 'popular_travel',
   priority: 7,
   criteria: {
     locationTypes: ['city-center', 'entertainment-district']
   }
 },

 // Theme-Based Categories
 instagrammable_places: {
   id: 'instagrammable_places',
   name: 'Instagrammable Places',
   description: 'Picture-perfect destinations',
   imageUrl: '/images/presets/instagrammable-places.webp',
   featured: true,
   groupId: 'theme_based',
   priority: 1
 },

 world_wonders: {
   id: 'world_wonders',
   name: 'World Wonders',
   description: 'Stay near world-famous landmarks',
   imageUrl: '/images/presets/world-wonders.webp',
   featured: true,
   groupId: 'theme_based',
   priority: 2
 },

 adults_only: {
   id: 'adults_only',
   name: 'Adults Only',
   description: 'Exclusive adult-only resorts',
   imageUrl: '/images/presets/adults-only.webp',
   featured: true,
   groupId: 'theme_based',
   priority: 3
 },

 all_inclusive: {
   id: 'all_inclusive',
   name: 'All-Inclusive Resorts',
   description: 'All-in-one vacation packages',
   imageUrl: '/images/presets/all-inclusive.webp',
   featured: false,
   groupId: 'theme_based',
   priority: 4
 },

 eco_friendly: {
   id: 'eco_friendly',
   name: 'Eco-Friendly Hotels',
   description: 'Sustainable and environmentally conscious stays',
   imageUrl: '/images/presets/eco-friendly.webp',
   featured: false,
   groupId: 'theme_based',
   priority: 5
 },

 pet_friendly: {
   id: 'pet_friendly',
   name: 'Pet-Friendly Hotels',
   description: 'Welcome your furry friends',
   imageUrl: '/images/presets/pet-friendly.webp',
   featured: false,
   groupId: 'theme_based',
   priority: 6
 },

 boutique_hotels: {
   id: 'boutique_hotels',
   name: 'Boutique Hotels',
   description: 'Unique and intimate accommodations',
   imageUrl: '/images/presets/boutique-hotels.webp',
   featured: false,
   groupId: 'theme_based',
   priority: 7
 },

 budget_friendly: {
   id: 'budget_friendly',
   name: 'Budget-Friendly Hotels',
   description: 'Great value accommodations',
   imageUrl: '/images/presets/budget-friendly.webp',
   featured: false,
   groupId: 'theme_based',
   priority: 8
 },

 // Location-Specific Categories
 beachfront: {
   id: 'beachfront',
   name: 'Beachfront Hotels',
   description: 'Direct access to beautiful beaches',
   imageUrl: '/images/presets/beachfront.webp',
   featured: true,
   groupId: 'location_specific',
   priority: 1
 },

 mountain_retreats: {
   id: 'mountain_retreats',
   name: 'Mountain Retreats',
   description: 'Scenic mountain accommodations',
   imageUrl: '/images/presets/mountain-retreats.webp',
   featured: false,
   groupId: 'location_specific',
   priority: 2
 },

 desert_resorts: {
   id: 'desert_resorts',
   name: 'Desert Resorts',
   description: 'Luxurious oases in the desert',
   imageUrl: '/images/presets/desert-resorts.webp',
   featured: false,
   groupId: 'location_specific',
   priority: 3
 },

 city_center: {
   id: 'city_center',
   name: 'City Center Hotels',
   description: 'Stay in the heart of the city',
   imageUrl: '/images/presets/city-center.webp',
   featured: true,
   groupId: 'location_specific',
   priority: 4
 },

 near_airports: {
   id: 'near_airports',
   name: 'Hotels Near Airports',
   description: 'Convenient airport locations',
   imageUrl: '/images/presets/near-airports.webp',
   featured: false,
   groupId: 'location_specific',
   priority: 5
 },

 near_marinas: {
   id: 'near_marinas',
   name: 'Hotels Near Marinas',
   description: 'Perfect for boat enthusiasts',
   imageUrl: '/images/presets/near-marinas.webp',
   featured: false,
   groupId: 'location_specific',
   priority: 6
 },

 countryside: {
   id: 'countryside',
   name: 'Countryside Stays',
   description: 'Peaceful rural retreats',
   imageUrl: '/images/presets/countryside.webp',
   featured: false,
   groupId: 'location_specific',
   priority: 7
 },

 // Activity-Oriented Categories
 sports_hotels: {
   id: 'sports_hotels',
   name: 'Sports Hotels',
   description: 'Perfect for sports enthusiasts',
   imageUrl: '/images/presets/sports-hotels.webp',
   featured: false,
   groupId: 'activity_oriented',
   priority: 1
 },

 golf_resorts: {
   id: 'golf_resorts',
   name: 'Golf Resorts',
   description: 'Premier golf destinations',
   imageUrl: '/images/presets/golf-resorts.webp',
   featured: true,
   groupId: 'activity_oriented',
   priority: 2
 },

 ski_resorts: {
   id: 'ski_resorts',
   name: 'Ski Resorts',
   description: 'Perfect winter sports destinations',
   imageUrl: '/images/presets/ski-resorts.webp',
   featured: false,
   groupId: 'activity_oriented',
   priority: 3
 },

 spa_wellness: {
   id: 'spa_wellness',
   name: 'Spa & Wellness Hotels',
   description: 'Relaxation and rejuvenation',
   imageUrl: '/images/presets/spa-wellness.webp',
   featured: true,
   groupId: 'activity_oriented',
   priority: 4
 },

 diving_snorkeling: {
   id: 'diving_snorkeling',
   name: 'Diving & Snorkeling Hotels',
   description: 'Best underwater experiences',
   imageUrl: '/images/presets/diving-snorkeling.webp',
   featured: false,
   groupId: 'activity_oriented',
   priority: 5
 },

 // Event-Oriented Categories
 wedding_venues: {
   id: 'wedding_venues',
   name: 'Wedding Venues',
   description: 'Perfect for your special day',
   imageUrl: '/images/presets/wedding-venues.webp',
   featured: true,
   groupId: 'event_oriented',
   priority: 1
 },

 conference_business: {
   id: 'conference_business',
   name: 'Conference Hotels',
   description: 'Ideal for business events',
   imageUrl: '/images/presets/conference-business.webp',
   featured: false,
   groupId: 'event_oriented',
   priority: 2
 },

 event_friendly: {
   id: 'event_friendly',
   name: 'Event-Friendly Hotels',
   description: 'Perfect for any occasion',
   imageUrl: '/images/presets/event-friendly.webp',
   featured: false,
   groupId: 'event_oriented',
   priority: 3
 },

 exhibitions_symposiums: {
   id: 'exhibitions_symposiums',
   name: 'Exhibition Venues',
   description: 'Ideal for large gatherings',
   imageUrl: '/images/presets/exhibitions-symposiums.webp',
   featured: false,
   groupId: 'event_oriented',
   priority: 4
 },

 // Luxury and Unique Stays
 celebrity_favorite: {
   id: 'celebrity_favorite',
   name: 'Celebrity-Favorite Hotels',
   description: 'Where the stars stay',
   imageUrl: '/images/presets/celebrity-favorite.webp',
   featured: true,
   groupId: 'luxury_unique',
   priority: 1
 },

 historical_hotels: {
   id: 'historical_hotels',
   name: 'Historical Hotels',
   description: 'Stay in living history',
   imageUrl: '/images/presets/historical-hotels.webp',
   featured: false,
   groupId: 'luxury_unique',
   priority: 2
 },

 private_villas: {
   id: 'private_villas',
   name: 'Private Villas',
   description: 'Exclusive private accommodations',
   imageUrl: '/images/presets/private-villas.webp',
   featured: true,
   groupId: 'luxury_unique',
   priority: 3
 },

 yacht_friendly: {
   id: 'yacht_friendly',
   name: 'Yacht-Friendly Hotels',
   description: 'Perfect for maritime enthusiasts',
   imageUrl: '/images/presets/yacht-friendly.webp',
   featured: true,
   groupId: 'luxury_unique',
   priority: 4
 },

 private_aviation: {
   id: 'private_aviation',
   name: 'Private Aviation Hotels',
   description: 'Luxury travel made easy',
   imageUrl: '/images/presets/private-aviation.webp',
   featured: false,
   groupId: 'luxury_unique',
   priority: 5
 },

 // Dining and Nightlife
 michelin_star: {
   id: 'michelin_star',
   name: 'Michelin-Star Restaurants',
   description: 'World-class dining experiences',
   imageUrl: '/images/presets/michelin-star.webp',
   featured: true,
   groupId: 'dining_nightlife',
   priority: 1
 },

 wine_gastronomy: {
   id: 'wine_gastronomy',
   name: 'Wine & Gastronomy',
   description: 'For food and wine lovers',
   imageUrl: '/images/presets/wine-gastronomy.webp',
   featured: false,
   groupId: 'dining_nightlife',
   priority: 2
 },

 vibrant_nightlife: {
   id: 'vibrant_nightlife',
   name: 'Vibrant Nightlife',
   description: 'Best party locations',
   imageUrl: '/images/presets/party-nightlife.webp',
   featured: false,
   groupId: 'dining_nightlife',
   priority: 3
 },

 // Culturally-Oriented
 halal_hotels: {
   id: 'halal_hotels',
   name: 'Halal Hotels',
   description: 'Halal-certified accommodations',
   imageUrl: '/images/presets/halal-hotels.webp',
   featured: false,
   groupId: 'culturally_oriented',
   priority: 1
 },

 kosher_hotels: {
   id: 'kosher_hotels',
   name: 'Kosher Hotels',
   description: 'Kosher-friendly stays',
   imageUrl: '/images/presets/kosher-hotels.webp',
   featured: false,
   groupId: 'culturally_oriented',
   priority: 2
 },

 cultural_immersion: {
   id: 'cultural_immersion',
   name: 'Cultural Immersion',
   description: 'Experience local culture',
   imageUrl: '/images/presets/cultural-immersion.webp',
   featured: false,
   groupId: 'culturally_oriented',
   priority: 3
 },

 traditional_riad: {
   id: 'traditional_riad',
   name: 'Traditional Riad Hotels',
   description: 'Authentic Moroccan experience',
   imageUrl: '/images/presets/traditional-riad.webp',
   featured: false,
   groupId: 'culturally_oriented',
   priority: 4
 },

 heritage_hotels: {
    id: 'heritage_hotels',
    name: 'Heritage Hotels',
    description: 'Historic and culturally rich stays',
    imageUrl: '/images/presets/heritage-hotels.webp', 
    featured: false,
    groupId: 'culturally_oriented',
    priority: 5
  },
 
 religious_retreats: {
    id: 'religious_retreats',
    name: 'Religious Retreats',
    description: 'Spiritual and peaceful stays',
    imageUrl: '/images/presets/religious-retreats.webp',
    featured: false,
    groupId: 'culturally_oriented',
    priority: 6
  },
 
  // Specialized Experiences 
  wellness_retreats: {
    id: 'wellness_retreats',
    name: 'Wellness Retreats',
    description: 'Focus on health and wellbeing',
    imageUrl: '/images/presets/wellness-retreats.webp',
    featured: true,
    groupId: 'specialized_experiences',
    priority: 1,
    criteria: {
      requiredAmenities: ['spa', 'fitness-center', 'yoga']
    }
  },
 
  honeymoon_hotels: {
    id: 'honeymoon_hotels',
    name: 'Honeymoon Hotels',
    description: 'Romantic escapes for newlyweds',
    imageUrl: '/images/presets/honeymoon-hotels.webp',
    featured: true,
    groupId: 'specialized_experiences',
    priority: 2,
    criteria: {
      minRating: 4.5,
      requiredAmenities: ['romantic-dining', 'spa']
    }
  },
 
  child_friendly: {
    id: 'child_friendly',
    name: 'Child-Friendly Hotels',
    description: 'Perfect for young families',
    imageUrl: '/images/presets/child-friendly.webp',
    featured: false,
    groupId: 'specialized_experiences',
    priority: 3,
    criteria: {
      requiredAmenities: ['kids-club', 'children-pool', 'playground']
    }
  },
 
  accessible_hotels: {
    id: 'accessible_hotels',
    name: 'Accessible Hotels',
    description: 'Fully accessible accommodations',
    imageUrl: '/images/presets/accessible-hotels.webp',
    featured: false,
    groupId: 'specialized_experiences',
    priority: 4,
    criteria: {
      requiredAmenities: ['accessible-rooms', 'elevator']
    }
  },
 
  // Highlight Features
  infinity_pools: {
    id: 'infinity_pools',
    name: 'Hotels with Infinity Pools',
    description: 'Stunning infinity pool views',
    imageUrl: '/images/presets/infinity-pools.webp',
    featured: true,
    groupId: 'highlight_features',
    priority: 1,
    criteria: {
      requiredAmenities: ['infinity-pool']
    }
  },
 
  stunning_views: {
    id: 'stunning_views',
    name: 'Hotels with Stunning Views',
    description: 'Breathtaking vistas guaranteed',
    imageUrl: '/images/presets/stunning-views.webp',
    featured: true,
    groupId: 'highlight_features',
    priority: 2,
    criteria: {
      locationTypes: ['scenic', 'waterfront', 'mountain']
    }
  },
 
  light_hotels: {
    id: 'light_hotels',
    name: 'Light Hotels',
    description: 'Hotels with exceptional lighting',
    imageUrl: '/images/presets/light-hotels.webp',
    featured: false,
    groupId: 'highlight_features',
    priority: 3,
    criteria: {
      requiredAmenities: ['natural-light', 'designer-lighting']
    }
  }
 };
 
 // Вспомогательные функции
 export function getFeaturedCategories(): HotelCategory[] {
  return Object.values(HOTEL_CATEGORIES)
    .filter(category => category.featured)
    .sort((a, b) => a.priority - b.priority);
 }
 
 export function getCategoriesByGroup(groupId: string): HotelCategory[] {
  return Object.values(HOTEL_CATEGORIES)
    .filter(category => category.groupId === groupId)
    .sort((a, b) => a.priority - b.priority);
 }
 
 export function getCategoryById(id: CategoryId): HotelCategory | undefined {
  return HOTEL_CATEGORIES[id];
 }
 
 export function getAllCategories(): HotelCategory[] {
  return Object.values(HOTEL_CATEGORIES)
    .sort((a, b) => a.priority - b.priority);
 }
 
 export function getRelatedCategories(categoryId: CategoryId, limit: number = 3): HotelCategory[] {
  const category = HOTEL_CATEGORIES[categoryId];
  if (!category) return [];
 
  return Object.values(HOTEL_CATEGORIES)
    .filter(c => c.id !== categoryId && c.groupId === category.groupId)
    .sort((a, b) => a.priority - b.priority)
    .slice(0, limit);
 }
 
 export function searchCategories(query: string): HotelCategory[] {
  const searchTerm = query.toLowerCase();
  return Object.values(HOTEL_CATEGORIES)
    .filter(category => 
      category.name.toLowerCase().includes(searchTerm) ||
      category.description.toLowerCase().includes(searchTerm)
    )
    .sort((a, b) => a.priority - b.priority);
 }
 
 export default {
  HOTEL_CATEGORIES,
  getFeaturedCategories,
  getCategoriesByGroup,
  getCategoryById,
  getAllCategories,
  getRelatedCategories,
  searchCategories
 };