import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { 
  Clock, 
  Users, 
  Info, 
  Phone, 
  Mail, 
  Globe,
  Building2,
  Languages,
  Calendar as CalendarIcon,
  CreditCard,
  Plus,
  Minus
} from 'lucide-react';
import { Card, CardContent } from '../../ui/card';
import { Button } from '../../ui/button';
import { Calendar } from '../../ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../ui/select';
import { Separator } from '../../ui/separator';
import { addDays, format } from 'date-fns';
import { useToast } from '../../../hooks/useToast';
import { cn } from '../../../lib/utils';

const QuickInfoItem = ({ icon: Icon, label, value }) => (
  <div className="flex items-center gap-2">
    <Icon className="w-4 h-4 text-gray-400" />
    <div>
      <div className="text-sm font-medium">{label}</div>
      <div className="text-sm text-gray-600">{value}</div>
    </div>
  </div>
);

const ContactInfoItem = ({ icon: Icon, value, href }) => (
  <div className="flex items-center gap-2">
    <Icon className="w-4 h-4 text-gray-400" />
    {href ? (
      <a href={href} className="text-sm text-brand-blue hover:underline">
        {value}
      </a>
    ) : (
      <div className="text-sm">{value}</div>
    )}
  </div>
);

const BookingForm = ({ hotelId, hotel, onBookingCreated }) => {
  const { toast } = useToast();
  const [dates, setDates] = useState({ from: null, to: null });
  const [guests, setGuests] = useState({ adults: 2, children: 0 });
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState('');
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isGuestsOpen, setIsGuestsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const calculateNights = () => {
    if (!dates.from || !dates.to) return 0;
    return Math.ceil((dates.to.getTime() - dates.from.getTime()) / (1000 * 60 * 60 * 24));
  };

  const calculateTotal = () => {
    const nights = calculateNights();
    if (!selectedRoom || !nights) return 0;
    return selectedRoom.price * nights;
  };

  const handleRoomSelect = (value) => {
    console.log('Room selection value:', value);
    
    if (!value) {
      setSelectedRoom(null);
      setSelectedRoomId('');
      return;
    }

    const selectedIndex = parseInt(value.split('_')[2]);
    const room = hotel.details.roomTypes[selectedIndex];

    console.log('Found room by index:', room);
    
    if (room) {
      const roomWithId = {
        ...room,
        id: `room_${hotel.id}_${selectedIndex}_${Date.now()}`
      };
      console.log('Setting room with ID:', roomWithId);
      setSelectedRoom(roomWithId);
      setSelectedRoomId(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!dates.from || !dates.to || !selectedRoom) {
      toast({
        title: "Missing Details",
        description: "Please select all required booking details",
        variant: "destructive"
      });
      return;
    }

    try {
      setIsSubmitting(true);
      console.log('Submitting with room:', selectedRoom);
      
      await onBookingCreated({
        hotelId,
        roomId: selectedRoom.id,
        checkIn: dates.from,
        checkOut: dates.to,
        guests,
        price: {
          base: calculateTotal(),
          tax: calculateTotal() * 0.2,
          total: calculateTotal() * 1.2
        }
      });
    } catch (error) {
      console.error('Booking error:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to create booking",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {/* Date Selection */}
      <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn(
              "w-full justify-start text-left font-normal",
              !dates.from && "text-muted-foreground"
            )}
            disabled={isSubmitting}
          >
            <CalendarIcon className="w-4 h-4 mr-2" />
            {dates.from ? (
              dates.to ? (
                <>
                  {format(dates.from, "LLL dd")} - {format(dates.to, "LLL dd")}
                </>
              ) : (
                format(dates.from, "LLL dd")
              )
            ) : (
              <span>Select dates</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={dates.from || new Date()}
            selected={{ from: dates.from, to: dates.to }}
            onSelect={(range) => {
              setDates({
                from: range?.from || null,
                to: range?.to || null
              });
              if (range?.to) setIsCalendarOpen(false);
            }}
            numberOfMonths={2}
            disabled={{ before: new Date() }}
          />
        </PopoverContent>
      </Popover>
 
      {/* Guests Selection */}
      <Popover open={isGuestsOpen} onOpenChange={setIsGuestsOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className="w-full justify-between text-left"
            disabled={isSubmitting}
          >
            <div className="flex items-center">
              <Users className="w-4 h-4 mr-2" />
              <span>
                {guests.adults} {guests.adults === 1 ? 'Adult' : 'Adults'}
                {guests.children > 0 && `, ${guests.children} ${guests.children === 1 ? 'Child' : 'Children'}`}
              </span>
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-80 p-4">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <div className="font-medium">Adults</div>
                <div className="text-sm text-muted-foreground">Age 13+</div>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  type="button"
                  size="sm"
                  variant="outline"
                  onClick={() => setGuests(prev => ({...prev, adults: Math.max(1, prev.adults - 1)}))}
                  disabled={guests.adults <= 1}
                >
                  <Minus className="h-4 w-4" />
                </Button>
                <span className="w-8 text-center">{guests.adults}</span>
                <Button
                  type="button"
                  size="sm"
                  variant="outline"
                  onClick={() => setGuests(prev => ({...prev, adults: Math.min(4, prev.adults + 1)}))}
                  disabled={guests.adults >= 4}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <div className="font-medium">Children</div>
                <div className="text-sm text-muted-foreground">Age 0-12</div>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  type="button"
                  size="sm"
                  variant="outline"
                  onClick={() => setGuests(prev => ({...prev, children: Math.max(0, prev.children - 1)}))}
                  disabled={guests.children <= 0}
                >
                  <Minus className="h-4 w-4" />
                </Button>
                <span className="w-8 text-center">{guests.children}</span>
                <Button
                  type="button"
                  size="sm"
                  variant="outline"
                  onClick={() => setGuests(prev => ({...prev, children: Math.min(4, prev.children + 1)}))}
                  disabled={guests.children >= 4}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
 
      {/* Room Selection */}
      <Select
        value={selectedRoomId}
        onValueChange={handleRoomSelect}
        disabled={isSubmitting}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select room type" />
        </SelectTrigger>
        <SelectContent>
          {hotel.details?.roomTypes?.map((room, index) => {
            const value = `room_${hotel.id}_${index}`;
            return (
              <SelectItem 
                key={value}
                value={value}
              >
                <div className="flex justify-between items-center gap-2">
                  <span>{room.name}</span>
                  <span className="text-muted-foreground">
                    ${room.price}/night
                  </span>
                </div>
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
 
      {/* Price Summary */}
      {selectedRoom && dates.from && dates.to && (
        <Card>
          <CardContent className="p-4 space-y-3">
            <div className="flex justify-between text-sm">
              <span>{selectedRoom.name} × {calculateNights()} nights</span>
              <span>${calculateTotal().toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm">
              <span>Taxes & fees (20%)</span>
              <span>${(calculateTotal() * 0.2).toFixed(2)}</span>
            </div>
            <Separator />
            <div className="flex justify-between font-medium">
              <span>Total</span>
              <span>${(calculateTotal() * 1.2).toFixed(2)}</span>
            </div>
          </CardContent>
        </Card>
      )}
 
      {/* Submit Button */}
      <Button 
        type="submit" 
        className="w-full" 
        disabled={!dates.from || !dates.to || !selectedRoom || isSubmitting}
      >
        {isSubmitting ? (
          <div className="flex items-center gap-2">
            <div className="animate-spin">●</div>
            Processing...
          </div>
        ) : (
          <>
            <CreditCard className="w-4 h-4 mr-2" />
            Book Now
          </>
        )}
      </Button>
 
      {/* Cancellation Policy */}
      {dates.from && (
        <p className="text-xs text-gray-500 text-center">
          Free cancellation until {format(addDays(dates.from, -1), "MMMM d, yyyy")}
        </p>
      )}
    </form>
  );
 };
 
 const Sidebar = ({ hotel, onBookingSubmit }) => {
  const hasContactInfo = hotel.contact?.phone || 
                        hotel.contact?.email || 
                        hotel.contact?.website;
 
  return (
    <div className="space-y-6">
      {/* Booking Form */}
      <Card className="sticky top-24">
        <CardContent className="p-6">
          <BookingForm 
            hotelId={hotel.id}
            hotel={hotel}
            onBookingCreated={onBookingSubmit}
          />
        </CardContent>
      </Card>
 
      {/* Quick Info */}
      <Card>
        <CardContent className="p-6">
          <h3 className="font-semibold mb-4">Quick Info</h3>
          <div className="space-y-4">
            <QuickInfoItem 
              icon={Clock}
              label="Built in"
              value={hotel.details?.yearBuilt || 'N/A'}
            />
            
            <QuickInfoItem 
              icon={Building2}
              label="Total Rooms"
              value={hotel.details?.rooms || 'N/A'}
            />
 
            {hotel.details?.lastRenovated && (
              <QuickInfoItem 
                icon={Info}
                label="Last Renovated"
                value={hotel.details.lastRenovated}
              />
            )}
 
            {hotel.details?.propertyType && (
              <QuickInfoItem 
                icon={Building2}
                label="Property Type"
                value={hotel.details.propertyType}
              />
            )}
 
            {hotel.details?.chainName && (
              <QuickInfoItem 
                icon={Building2}
                label="Hotel Chain"
                value={hotel.details.chainName}
              />
            )}
          </div>
        </CardContent>
      </Card>
 
      {/* Contact Info */}
      <Card>
        <CardContent className="p-6">
          <h3 className="font-semibold mb-4">Contact Information</h3>
          <div className="space-y-4">
            {hotel.contact?.phone && (
              <ContactInfoItem 
                icon={Phone}
                value={hotel.contact.phone}
                href={`tel:${hotel.contact.phone}`}
              />
            )}
 
            {hotel.contact?.email && (
              <ContactInfoItem 
                icon={Mail}
                value={hotel.contact.email}
                href={`mailto:${hotel.contact.email}`}
              />
            )}
 
            {hotel.contact?.website && (
              <ContactInfoItem 
                icon={Globe}
                value={hotel.contact.website}
                href={hotel.contact.website}
              />
            )}
 
            {!hasContactInfo && (
              <div className="text-sm text-gray-500">
                Contact information not available
              </div>
            )}
          </div>
        </CardContent>
      </Card>
 
      {/* Languages Spoken */}
      {hotel.details?.languages?.length > 0 && (
        <Card>
          <CardContent className="p-6">
            <h3 className="font-semibold mb-4">
              <div className="flex items-center gap-2">
                <Languages className="w-5 h-5" />
                Languages Spoken
              </div>
            </h3>
            <div className="flex flex-wrap gap-2">
              {hotel.details.languages.map((language) => (
                <span 
                  key={language}
                  className="text-sm bg-gray-100 px-3 py-1 rounded-full"
                >
                  {language}
                </span>
              ))}
            </div>
          </CardContent>
        </Card>
      )}
 
      {/* Hotel Policies */}
      {hotel.details?.policies && (
        <Card>
          <CardContent className="p-6">
            <h3 className="font-semibold mb-4">Hotel Policies</h3>
            <div className="space-y-3 text-sm">
              {Object.entries(hotel.details.policies).map(([key, value]) => (
                <div key={key}>
                  <span className="font-medium capitalize">
                    {key.replace(/([A-Z])/g, ' $1').trim()}:
                  </span>{' '}
                  <span className="text-gray-600">
                    {value || 'Not specified'}
                  </span>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
 };
 
 // PropTypes
 QuickInfoItem.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
 };
 
 ContactInfoItem.propTypes = {
  icon: PropTypes.elementType.isRequired,
  value: PropTypes.string.isRequired,
  href: PropTypes.string
 };
 
 BookingForm.propTypes = {
  hotelId: PropTypes.string.isRequired,
  hotel: PropTypes.shape({
    details: PropTypes.shape({
      roomTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
      }))
    })
  }).isRequired,
  onBookingCreated: PropTypes.func.isRequired
 };
 
 Sidebar.propTypes = {
  hotel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    details: PropTypes.shape({
      yearBuilt: PropTypes.string,
      rooms: PropTypes.string,
      lastRenovated: PropTypes.string,
      propertyType: PropTypes.string,
      chainName: PropTypes.string,
      languages: PropTypes.arrayOf(PropTypes.string),
      policies: PropTypes.object,
      roomTypes: PropTypes.array
    }),
    contact: PropTypes.shape({
      phone: PropTypes.string,
      email: PropTypes.string,
      website: PropTypes.string
    })
  }).isRequired,
  onBookingSubmit: PropTypes.func.isRequired
 };
 
 export default Sidebar;