// src/components/PitchDeck/components/slides/TractionSlide.tsx
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Slide } from '../Slide';
import { Users, Star, ThumbsUp, Award } from 'lucide-react';

const traction = [
  { metric: '1,000+', label: 'Beta Users', icon: Users },
  { metric: '20+', label: 'Influencer Partners', icon: Star },
  { metric: '98%', label: 'Positive Feedback', icon: ThumbsUp }
];

const achievements = [
  'Successfully completed beta testing with 1,000+ users',
  'Established partnerships with 20+ travel influencers',
  'Developed proprietary AI-driven rating algorithm',
  'Secured initial hotel partnerships in key markets',
  'Achieved 98% user satisfaction rate',
  'Built scalable cloud infrastructure'
];

const quarterlyGoals = [
  {
    quarter: 'Q1 2025',
    goals: [
      'Launch mobile applications',
      'Expand to 3 new markets',
      'Reach 5,000 active users'
    ]
  },
  {
    quarter: 'Q2 2025',
    goals: [
      'Implement AI recommendations',
      'Launch premium features',
      'Secure 50+ hotel partnerships'
    ]
  }
];

export const TractionSlide: React.FC = () => (
  <Slide title="Traction & Milestones">
    <div className="space-y-8">
      <div className="grid md:grid-cols-3 gap-8">
        {traction.map((item, index) => (
          <Card key={index}>
            <CardContent className="p-6 text-center">
              <item.icon className="w-12 h-12 text-primary-600 mx-auto mb-4" />
              <div className="text-3xl font-bold text-primary-600 mb-2">
                {item.metric}
              </div>
              <div className="text-gray-600">{item.label}</div>
            </CardContent>
          </Card>
        ))}
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        <Card>
          <CardContent className="p-6">
            <h3 className="text-xl font-bold mb-4">Key Achievements</h3>
            <div className="space-y-4">
              {achievements.map((achievement, index) => (
                <div key={index} className="flex items-center gap-2">
                  <Award className="w-5 h-5 text-primary-600 flex-shrink-0" />
                  <span className="text-gray-600">{achievement}</span>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="p-6">
            <h3 className="text-xl font-bold mb-4">Growth Roadmap</h3>
            <div className="space-y-6">
              {quarterlyGoals.map((quarter, index) => (
                <div key={index} className="space-y-2">
                  <h4 className="font-semibold text-primary-600">
                    {quarter.quarter}
                  </h4>
                  <ul className="space-y-2">
                    {quarter.goals.map((goal, goalIndex) => (
                      <li key={goalIndex} className="flex items-center gap-2">
                        <div className="w-2 h-2 bg-primary-600 rounded-full" />
                        <span className="text-gray-600">{goal}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-bold mb-4">Key Performance Indicators</h3>
          <div className="grid md:grid-cols-4 gap-6">
            {[
              { label: 'User Retention', value: '85%' },
              { label: 'Engagement Rate', value: '72%' },
              { label: 'Booking Conversion', value: '12%' },
              { label: 'Hotel Partner Satisfaction', value: '95%' }
            ].map((kpi, index) => (
              <div key={index} className="text-center p-4 bg-gray-50 rounded-lg">
                <div className="text-2xl font-bold text-primary-600 mb-1">
                  {kpi.value}
                </div>
                <div className="text-sm text-gray-600">{kpi.label}</div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  </Slide>
);