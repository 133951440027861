// src/components/PitchDeck/components/slides/TeamSlide.tsx
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Slide } from '../Slide';
import { Star, Award, Users, Briefcase } from 'lucide-react';

const team = [
  {
    name: 'Alexander Green',
    role: 'Founder & CEO',
    background: [
      '18+ years in hospitality and marketing',
      'Expert in building dynamic platforms',
      'Previously led major travel tech initiatives'
    ],
    img: '/api/placeholder/150/150'
  },
  {
    name: 'Frank Satar',
    role: 'Development Director',
    background: [
      'Experienced Sales Director',
      'Tech development expert',
      '12+ years in SaaS platforms'
    ],
    img: '/api/placeholder/150/150'
  }
];

const advisors = [
  {
    name: 'Sarah Johnson',
    role: 'Strategic Advisor',
    credentials: 'Former VP at Booking.com',
    expertise: 'Global Hospitality Markets'
  },
  {
    name: 'Dr. Michael Chen',
    role: 'Technical Advisor',
    credentials: 'AI Research Lead',
    expertise: 'Machine Learning & Personalization'
  }
];

export const TeamSlide: React.FC = () => (
  <Slide title="Our Team">
    <div className="space-y-8">
      {/* Core Team */}
      <div className="grid md:grid-cols-2 gap-8">
        {team.map((member, index) => (
          <Card key={index}>
            <CardContent className="p-6">
              <div className="flex items-start gap-6">
                <img 
                  src={member.img} 
                  alt={member.name}
                  className="w-32 h-32 rounded-full object-cover"
                />
                <div>
                  <h3 className="text-xl font-bold mb-2">{member.name}</h3>
                  <p className="text-primary-600 font-semibold mb-4">{member.role}</p>
                  <ul className="space-y-2">
                    {member.background.map((item, idx) => (
                      <li key={idx} className="flex items-center gap-2">
                        <Star className="w-4 h-4 text-primary-600" />
                        <span className="text-gray-600">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Advisors */}
      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-bold mb-4">Advisory Board</h3>
          <div className="grid md:grid-cols-2 gap-6">
            {advisors.map((advisor, index) => (
              <div key={index} className="flex items-start gap-4 p-4 bg-gray-50 rounded-lg">
                <Award className="w-8 h-8 text-primary-600 flex-shrink-0" />
                <div>
                  <h4 className="font-semibold">{advisor.name}</h4>
                  <p className="text-primary-600">{advisor.role}</p>
                  <p className="text-sm text-gray-600 mt-1">{advisor.credentials}</p>
                  <p className="text-sm text-gray-600">{advisor.expertise}</p>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Team Stats */}
      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-bold mb-4">Team Highlights</h3>
          <div className="grid md:grid-cols-4 gap-6">
            {[
              { icon: Briefcase, value: '45+', label: 'Years Combined Experience' },
              { icon: Star, value: '12+', label: 'Industry Awards' },
              { icon: Users, value: '5+', label: 'Successful Exits' },
              { icon: Award, value: '20+', label: 'Patents & Publications' }
            ].map((stat, index) => (
              <div key={index} className="text-center">
                <stat.icon className="w-8 h-8 text-primary-600 mx-auto mb-2" />
                <div className="text-2xl font-bold text-gray-900">{stat.value}</div>
                <div className="text-sm text-gray-600">{stat.label}</div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Open Positions */}
      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-bold mb-4">We're Growing</h3>
          <div className="grid md:grid-cols-3 gap-4">
            {[
              'Senior Full-Stack Developer',
              'AI/ML Engineer',
              'Product Manager'
            ].map((position, index) => (
              <div key={index} className="p-3 bg-gray-50 rounded-lg text-center">
                <Briefcase className="w-6 h-6 text-primary-600 mx-auto mb-2" />
                <span className="text-gray-700">{position}</span>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  </Slide>
);