// src/pages/admin/AdminDashboardPage.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Hotel,
  Users,
  BookOpen,
  MessageSquare,
  TrendingUp,
  Activity,
  DollarSign,
  Calendar,
  Mail,
  Star,
  Globe
} from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import HotelsStatsCard from '@/components/admin/HotelsStatsCard';
import bookingService from '@/services/booking/BookingService';
import { ROUTES } from '@/config/paths';

interface DashboardMetrics {
  bookings: {
    total: number;
    active: number;
    pending: number;
    revenue: number;
  };
  messages: {
    total: number;
    unread: number;
    spam: number;
  };
  users: {
    total: number;
    active: number;
    new: number;
  };
}

interface NavigationCard {
  title: string;
  description: string;
  icon: React.ElementType;
  route: string;
  color: string;
  metrics?: string | number;
  trend?: {
    value: number;
    isPositive: boolean;
  };
}

const AdminDashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const [metrics, setMetrics] = useState<DashboardMetrics>({
    bookings: { total: 0, active: 0, pending: 0, revenue: 0 },
    messages: { total: 0, unread: 0, spam: 0 },
    users: { total: 0, active: 0, new: 0 }
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadDashboardData();
  }, []);

  const loadDashboardData = async () => {
    try {
      setLoading(true);
      const [bookingStats, messageStats, userStats] = await Promise.all([
        bookingService.getBookingStats(),
        // Здесь должны быть вызовы других сервисов для получения статистики
        // messageService.getMessageStats(),
        // userService.getUserStats()
      ]);

      setMetrics({
        bookings: {
          total: bookingStats.total || 0,
          active: bookingStats.byStatus?.confirmed || 0,
          pending: bookingStats.byStatus?.pending || 0,
          revenue: bookingStats.totalRevenue || 0
        },
        messages: {
          total: 0, // messageStats.total,
          unread: 0, // messageStats.unread,
          spam: 0 // messageStats.spam
        },
        users: {
          total: 0, // userStats.total,
          active: 0, // userStats.active,
          new: 0 // userStats.new
        }
      });
    } catch (error) {
      console.error('Error loading dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  const navigationCards: NavigationCard[] = [
    {
      title: "Bookings",
      description: "Manage hotel bookings",
      icon: BookOpen,
      route: ROUTES.ADMIN_BOOKINGS,
      color: "text-blue-600",
      metrics: metrics.bookings.total,
      trend: {
        value: 12.5,
        isPositive: true
      }
    },
    {
      title: "Messages",
      description: "Handle customer inquiries",
      icon: MessageSquare,
      route: ROUTES.ADMIN_CONTACT_MESSAGES,
      color: "text-green-600",
      metrics: metrics.messages.unread,
      trend: {
        value: 5.2,
        isPositive: true
      }
    },
    {
      title: "Hotels",
      description: "Manage hotel listings",
      icon: Hotel,
      route: ROUTES.ADMIN_HOTELS,
      color: "text-purple-600",
      metrics: "Database",
      trend: {
        value: 8.4,
        isPositive: true
      }
    },
    {
      title: "Users",
      description: "Manage user accounts",
      icon: Users,
      route: ROUTES.ADMIN_USERS,
      color: "text-orange-600",
      metrics: metrics.users.total,
      trend: {
        value: 3.2,
        isPositive: false
      }
    }
  ];

  const quickStats = [
    {
      title: "Total Bookings",
      value: metrics.bookings.total,
      icon: Activity,
      description: "All time bookings"
    },
    {
      title: "Active Bookings",
      value: metrics.bookings.active,
      icon: TrendingUp,
      description: "Currently active"
    },
    {
      title: "Total Revenue",
      value: `$${metrics.bookings.revenue.toFixed(2)}`,
      icon: DollarSign,
      description: "All time revenue"
    },
    {
      title: "Pending Bookings",
      value: metrics.bookings.pending,
      icon: Calendar,
      description: "Awaiting confirmation"
    }
  ];

  return (
    <div className="container p-6 mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Admin Dashboard</h1>
        <Button 
          variant="outline"
          onClick={() => navigate(ROUTES.HOME)}
        >
          Return to Site
        </Button>
      </div>

      {/* Quick Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        {quickStats.map((stat, index) => (
          <Card key={index}>
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium">
                {stat.title}
              </CardTitle>
              <stat.icon className="h-4 w-4 text-gray-600" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {typeof stat.value === 'number' ? stat.value.toLocaleString() : stat.value}
              </div>
              <p className="text-xs text-gray-600">
                {stat.description}
              </p>
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Hotels Database Stats */}
      <div className="mb-6">
        <HotelsStatsCard />
      </div>

      {/* Navigation Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {navigationCards.map((card, index) => {
          const Icon = card.icon;
          return (
            <Card 
              key={index}
              className="cursor-pointer hover:shadow-lg transition-shadow"
              onClick={() => navigate(card.route)}
            >
              <CardContent className="pt-6">
                <div className="flex flex-col items-center text-center space-y-3">
                  <Icon className={`w-8 h-8 ${card.color}`} />
                  <div>
                    <h3 className="font-semibold">{card.title}</h3>
                    <p className="text-sm text-gray-600 mb-2">{card.description}</p>
                    {card.metrics && (
                      <div className="text-lg font-semibold">
                        {typeof card.metrics === 'number' ? card.metrics.toLocaleString() : card.metrics}
                      </div>
                    )}
                    {card.trend && (
                      <div className={`text-sm ${card.trend.isPositive ? 'text-green-600' : 'text-red-600'}`}>
                        {card.trend.isPositive ? '↑' : '↓'} {card.trend.value}%
                      </div>
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>
          );
        })}
      </div>
      
      {/* Recent Activity */}
      <div className="mt-6">
        <Card>
          <CardHeader>
            <CardTitle>Recent Activity</CardTitle>
          </CardHeader>
          <CardContent>
            {loading ? (
              <div className="animate-pulse space-y-4">
                {[...Array(5)].map((_, i) => (
                  <div key={i} className="h-4 bg-gray-200 rounded w-full" />
                ))}
              </div>
            ) : (
              <div className="text-sm text-gray-600">
                Activity feed will be displayed here...
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AdminDashboardPage;