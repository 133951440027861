// src/components/PitchDeck/components/slides/SolutionSlide.tsx
import React from 'react';
import {
  Brain,
  Video,
  Globe,
  Coins,
  Map,
  Award,
  BookOpen
} from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';
import { Slide } from '../Slide';
import { CardWithIcon } from '../CardWithIcon';

const features = [
  {
    icon: Brain,
    title: 'AI-Driven',
    description: 'Personalized recommendations based on user preferences'
  },
  {
    icon: Video,
    title: 'Video Reviews',
    description: 'Authentic content from verified creators'
  },
  {
    icon: Globe,
    title: 'Global Platform',
    description: 'Coverage across 150+ countries worldwide'
  },
  {
    icon: Coins,
    title: 'Rewards',
    description: 'Blockchain-based incentives for engagement'
  }
];

const additionalFeatures = [
  { icon: Map, title: 'Interactive Maps', description: 'Visual hotel discovery' },
  { icon: Award, title: 'Category Rankings', description: 'Specialized hotel lists' },
  { icon: BookOpen, title: 'Direct Booking', description: 'Seamless reservations' },
  { icon: Coins, title: 'Reward System', description: 'Blockchain-based incentives' }
];

export const SolutionSlide: React.FC = () => (
  <Slide title="Our Solution">
    <div className="space-y-8">
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
        {features.map((feature, index) => (
          <CardWithIcon
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </div>

      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-bold mb-4">Key Platform Features</h3>
          <div className="grid md:grid-cols-2 gap-6">
            {additionalFeatures.map((feature, index) => (
              <div key={index} className="flex items-start gap-3">
                <feature.icon className="w-6 h-6 text-primary-600 flex-shrink-0" />
                <div>
                  <span className="font-semibold">{feature.title}</span>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-bold mb-4">How It Works</h3>
          <div className="grid md:grid-cols-3 gap-6">
            {[
              {
                step: '1',
                title: 'Smart Profile',
                description: 'AI analyzes user preferences and travel history'
              },
              {
                step: '2',
                title: 'Real-time Data',
                description: 'Continuous monitoring of hotel performance and reviews'
              },
              {
                step: '3',
                title: 'Personalized Match',
                description: 'Precise hotel recommendations based on individual needs'
              }
            ].map((item, index) => (
              <div key={index} className="text-center">
                <div className="w-10 h-10 bg-primary-600 text-white rounded-full flex items-center justify-center mx-auto mb-3">
                  {item.step}
                </div>
                <h4 className="font-semibold mb-2">{item.title}</h4>
                <p className="text-gray-600 text-sm">{item.description}</p>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  </Slide>
);