// src/components/search/SearchForm.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MapPin, Calendar, Users, Search, Loader2 } from 'lucide-react';
import { format } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Calendar as CalendarComponent } from '@/components/ui/calendar';
import { useAuth } from '@/contexts/AuthContext';
import { useToast } from '@/hooks/useToast';
import { DateRange } from 'react-day-picker';

interface Location {
 code: string;
 name: string;
 type: string;
}

const DESTINATIONS: Location[] = [
  { code: 'PAR', name: 'Paris, France', type: 'Popular' },
  { code: 'LON', name: 'London, United Kingdom', type: 'Popular' },
  { code: 'NYC', name: 'New York, USA', type: 'Popular' },
  { code: 'TYO', name: 'Tokyo, Japan', type: 'Popular' },
  { code: 'ROM', name: 'Rome, Italy', type: 'Popular' },
  { code: 'BCN', name: 'Barcelona, Spain', type: 'Popular' },
  { code: 'BER', name: 'Berlin, Germany', type: 'Popular' },
  { code: 'AMS', name: 'Amsterdam, Netherlands', type: 'Europe' },
  { code: 'SIN', name: 'Singapore', type: 'Asia' },
  { code: 'HKG', name: 'Hong Kong', type: 'Asia' },
  { code: 'DXB', name: 'Dubai, UAE', type: 'Middle East' },
  { code: 'SYD', name: 'Sydney, Australia', type: 'Australia' },
  { code: 'LAX', name: 'Los Angeles, USA', type: 'Americas' },
  { code: 'MIA', name: 'Miami, USA', type: 'Americas' },
  { code: 'MAD', name: 'Madrid, Spain', type: 'Europe' },
  { code: 'MIL', name: 'Milan, Italy', type: 'Europe' },
  { code: 'BKK', name: 'Bangkok, Thailand', type: 'Asia' },
  { code: 'IST', name: 'Istanbul, Turkey', type: 'Europe' },
  { code: 'SEL', name: 'Seoul, South Korea', type: 'Asia' },
  { code: 'CPH', name: 'Copenhagen, Denmark', type: 'Europe' }
 ];

interface FormData {
 destination: string;
 checkIn: Date;
 checkOut: Date;
 guests: number;
}

const SearchForm: React.FC = () => {
 const navigate = useNavigate();
 const { currentUser } = useAuth();
 const [searchParams] = useSearchParams();
 const { toast } = useToast();

 const [destinationInput, setDestinationInput] = useState('');
 const [filteredDestinations, setFilteredDestinations] = useState(DESTINATIONS);
 const [selectedDestination, setSelectedDestination] = useState<Location | null>(null);
 const [isDestinationOpen, setIsDestinationOpen] = useState(false);
 const [calendarOpen, setCalendarOpen] = useState(false);
 const [isSubmitting, setIsSubmitting] = useState(false);

 const [formData, setFormData] = useState<FormData>({
   destination: searchParams.get('destination') || '',
   checkIn: searchParams.get('checkIn') ? new Date(searchParams.get('checkIn')!) : new Date(),
   checkOut: searchParams.get('checkOut') 
     ? new Date(searchParams.get('checkOut')!) 
     : new Date(Date.now() + 24 * 60 * 60 * 1000),
   guests: parseInt(searchParams.get('guests') || '2')
 });

 useEffect(() => {
   const handleClickOutside = (event: MouseEvent) => {
     if (!document.querySelector('.search-form-container')?.contains(event.target as Node)) {
       setIsDestinationOpen(false);
     }
   };

   document.addEventListener('mousedown', handleClickOutside);
   return () => document.removeEventListener('mousedown', handleClickOutside);
 }, []);

 useEffect(() => {
   if (!destinationInput) {
     setFilteredDestinations(DESTINATIONS.filter(d => d.type === 'Popular'));
     return;
   }

   const filtered = DESTINATIONS.filter(d => 
     d.name.toLowerCase().includes(destinationInput.toLowerCase()) ||
     d.code.toLowerCase().includes(destinationInput.toLowerCase())
   );
   setFilteredDestinations(filtered);
 }, [destinationInput]);

 const handleSubmit = async (e: React.FormEvent) => {
   e.preventDefault();
   setIsSubmitting(true);

   try {
     const queryParams = new URLSearchParams({
       destination: formData.destination,
       destinationCode: selectedDestination?.code || '',
       checkIn: formData.checkIn.toISOString(),
       checkOut: formData.checkOut.toISOString(),
       guests: formData.guests.toString(),
       ...(currentUser?.preferences && {
         preferences: JSON.stringify(currentUser.preferences)
       })
     });

     navigate(`/search?${queryParams.toString()}`);
   } catch (error) {
     toast({
       title: "Error",
       description: error instanceof Error ? error.message : 'An error occurred',
       variant: "destructive"
     });
   } finally {
     setIsSubmitting(false);
   }
 };

 return (
   <motion.form 
     onSubmit={handleSubmit}
     initial={{ opacity: 0, y: 20 }}
     animate={{ opacity: 1, y: 0 }}
     className="search-form-container"
   >
     <div className="grid grid-cols-1 md:grid-cols-12 gap-4 p-4 bg-white/90 backdrop-blur-md rounded-xl shadow-lg">
       {/* Destination */}
       <div className="md:col-span-5 space-y-2">
         <Label htmlFor="destination" className="text-gray-700">Where</Label>
         <div className="relative">
           <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-4 h-4" />
           <Input
             id="destination"
             value={destinationInput}
             onChange={(e) => setDestinationInput(e.target.value)}
             onFocus={() => setIsDestinationOpen(true)}
             placeholder="Where are you going?"
             className="pl-10 h-12 border-gray-200 focus:ring-2 ring-blue-500"
           />
           <AnimatePresence>
             {isDestinationOpen && (
               <motion.div
                 initial={{ opacity: 0, y: -10 }}
                 animate={{ opacity: 1, y: 0 }}
                 exit={{ opacity: 0, y: -10 }}
                 className="absolute top-full left-0 right-0 mt-2 bg-white rounded-lg shadow-xl border border-gray-100 z-50"
               >
                 {Object.entries(
                   filteredDestinations.reduce<Record<string, Location[]>>((acc, dest) => {
                     if (!acc[dest.type]) acc[dest.type] = [];
                     acc[dest.type].push(dest);
                     return acc;
                   }, {})
                 ).map(([type, locations]) => (
                   <div key={type}>
                     <div className="px-3 py-2 text-xs font-medium text-gray-500 uppercase">
                       {type}
                     </div>
                     {locations.map((location) => (
                       <motion.div
                         key={location.code}
                         whileHover={{ backgroundColor: 'rgb(243 244 246)' }}
                         className="px-3 py-2 cursor-pointer"
                         onClick={() => {
                           setSelectedDestination(location);
                           setDestinationInput(location.name);
                           setIsDestinationOpen(false);
                         }}
                       >
                         <div className="flex items-center gap-2">
                           <MapPin className="w-4 h-4 text-gray-400" />
                           <span>{location.name}</span>
                           <span className="ml-auto text-sm text-gray-400">
                             {location.code}
                           </span>
                         </div>
                       </motion.div>
                     ))}
                   </div>
                 ))}
               </motion.div>
             )}
           </AnimatePresence>
         </div>
       </div>

       {/* Dates */}
       <div className="md:col-span-4 space-y-2">
         <Label className="text-gray-700">When</Label>
         <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
           <PopoverTrigger asChild>
             <Button
               variant="outline"
               className={cn(
                 "w-full h-12 font-normal justify-start text-left",
                 !formData.checkIn && "text-muted-foreground"
               )}
             >
               <Calendar className="mr-2 h-4 w-4" />
               {formData.checkIn ? (
                 format(formData.checkIn, "LLL dd, y")
               ) : (
                 "Pick a date"
               )}
               {formData.checkOut && " - " + format(formData.checkOut, "LLL dd, y")}
             </Button>
           </PopoverTrigger>
           <PopoverContent className="w-auto p-0" align="start">
             <CalendarComponent
               initialFocus
               mode="range"
               defaultMonth={formData.checkIn}
               selected={{
                 from: formData.checkIn,
                 to: formData.checkOut
               }}
               onSelect={(range: DateRange | undefined) => {
                 if (range?.from) {
                   setFormData(prev => ({
                     ...prev,
                     checkIn: range.from!,
                     checkOut: range.to || new Date(range.from.getTime() + 24 * 60 * 60 * 1000)
                   }));
                   if (range.to) setCalendarOpen(false);
                 }
               }}
               numberOfMonths={2}
               disabled={(date) => date < new Date()}
             />
           </PopoverContent>
         </Popover>
       </div>

       {/* Guests */}
       <div className="md:col-span-3 space-y-2">
         <Label htmlFor="guests" className="text-gray-700">Guests</Label>
         <div className="relative">
           <Users className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-4 h-4" />
           <Input
             type="number"
             id="guests"
             min={1}
             max={10}
             className="pl-10 h-12 border-gray-200"
             value={formData.guests}
             onChange={(e) => setFormData(prev => ({
               ...prev,
               guests: Math.max(1, Math.min(10, parseInt(e.target.value) || 1))
             }))}
           />
         </div>
       </div>

       {/* Search Button */}
       <motion.div 
         className="md:col-span-12"
         whileHover={{ scale: 1.01 }}
         whileTap={{ scale: 0.99 }}
       >
         <Button
           type="submit"
           className="w-full h-12 bg-blue-500 hover:bg-blue-600 text-white"
           disabled={isSubmitting}
         >
           {isSubmitting ? (
             <>
               <Loader2 className="mr-2 h-4 w-4 animate-spin" />
               Searching...
             </>
           ) : (
             <>
               <Search className="mr-2 h-4 w-4" />
               Search Hotels
             </>
           )}
         </Button>
       </motion.div>
     </div>
   </motion.form>
 );
};

export default SearchForm;