// src/components/PitchDeck/components/CardWithIcon.tsx
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import type { CardWithIconProps } from '../types';

export const CardWithIcon: React.FC<CardWithIconProps> = ({ 
  icon: Icon, 
  title, 
  description, 
  className = "" 
}) => (
  <Card className={className}>
    <CardContent className="p-6">
      <Icon className="w-12 h-12 text-primary-600 mb-4" />
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </CardContent>
  </Card>
);