// src/components/admin/HotelsStatsCard.tsx

import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Hotel, Star } from 'lucide-react';
import hotelDatabaseService from '@/services/db/HotelDatabaseService';

const HotelsStatsCard: React.FC = () => {
  const [stats, setStats] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadStats();
  }, []);

  const loadStats = async () => {
    try {
        // В методе loadStats заменим:
        
      //const data = await hotelDatabaseService.getHotelStats();
      const data = await hotelDatabaseService.getMockHotelStats(); // Временно используем мок-данные
      setStats(data);
    } catch (error) {
      console.error('Error loading hotel stats:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Card>
        <CardContent className="p-6">
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-4 py-1">
              <div className="h-4 bg-gray-200 rounded w-3/4"></div>
              <div className="space-y-2">
                <div className="h-4 bg-gray-200 rounded"></div>
                <div className="h-4 bg-gray-200 rounded w-5/6"></div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Hotel className="h-5 w-5" />
          Hotels Database Statistics
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <div className="text-2xl font-bold">{stats?.totalHotels.toLocaleString()}</div>
            <div className="text-sm text-gray-500">Total Hotels</div>
          </div>
          
          <div>
            <div className="text-2xl font-bold">
              {stats?.ratingDistribution?.find(r => r.rating === '5')?.count.toLocaleString() || 0}
            </div>
            <div className="text-sm text-gray-500">5-Star Hotels</div>
          </div>

          <div>
            <div className="text-2xl font-bold">{stats?.countriesCount.toLocaleString()}</div>
            <div className="text-sm text-gray-500">Countries</div>
          </div>

          <div>
            <div className="text-2xl font-bold">{stats?.citiesCount.toLocaleString()}</div>
            <div className="text-sm text-gray-500">Cities</div>
          </div>
        </div>

        <div className="mt-6">
          <h4 className="text-sm font-semibold mb-2">Rating Distribution</h4>
          <div className="space-y-2">
            {stats?.ratingDistribution?.map((item: any) => (
              <div key={item.rating} className="flex items-center gap-2">
                <div className="flex items-center gap-1 w-20">
                  <span>{item.rating}</span>
                  <Star className="h-4 w-4 text-yellow-500" />
                </div>
                <div className="flex-1 h-2 bg-gray-100 rounded-full overflow-hidden">
                  <div
                    className="h-full bg-primary rounded-full"
                    style={{ width: `${item.percentage}%` }}
                  />
                </div>
                <div className="w-20 text-sm text-gray-500">
                  {item.percentage}%
                </div>
              </div>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default HotelsStatsCard;