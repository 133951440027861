// src/components/PitchDeck/components/slides/FundingSlide.tsx
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Slide } from '../Slide';
import { PieChart, ResponsiveContainer, Pie, Cell, Tooltip } from 'recharts';

const fundingAllocation = [
  { name: 'Marketing & Growth', percent: 50 },
  { name: 'Technology Development', percent: 30 },
  { name: 'Operations', percent: 20 }
];

const COLORS = ['#0ea5e9', '#6366f1', '#8b5cf6'];

export const FundingSlide: React.FC = () => {
  const pieData = fundingAllocation.map(item => ({
    name: item.name,
    value: item.percent
  }));

  return (
    <Slide title="Investment Opportunity">
      <div className="space-y-8">
        <div className="grid md:grid-cols-2 gap-8">
          <Card>
            <CardContent className="p-6">
              <h3 className="text-xl font-bold mb-4">Funding Request</h3>
              <div className="space-y-6">
                <div className="p-6 bg-gray-50 rounded-lg">
                  <div className="text-4xl font-bold text-primary-600 mb-2">$500K</div>
                  <div className="text-lg text-gray-600">Seeking for 10% equity</div>
                  <div className="mt-4 text-gray-600">
                    Pre-money valuation: $4.5M
                  </div>
                </div>

                <div className="space-y-4">
                  {fundingAllocation.map((item, index) => (
                    <div key={index}>
                      <div className="flex justify-between mb-2">
                        <span>{item.name}</span>
                        <span>{item.percent}%</span>
                      </div>
                      <div className="h-2 bg-gray-200 rounded-full">
                        <div 
                          className="h-2 bg-primary-600 rounded-full" 
                          style={{width: `${item.percent}%`}} 
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="p-6">
              <h3 className="text-xl font-bold mb-4">Use of Funds</h3>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={pieData}
                      innerRadius={60}
                      outerRadius={80}
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className="grid grid-cols-3 gap-4 mt-4">
                {fundingAllocation.map((item, index) => (
                  <div key={index} className="text-center">
                    <div className="w-4 h-4 rounded-full mx-auto mb-2" 
                         style={{ backgroundColor: COLORS[index] }} />
                    <div className="text-sm text-gray-600">{item.name}</div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>

        <Card>
          <CardContent className="p-6">
            <div className="grid md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-xl font-bold mb-4">Previous Funding</h3>
                <div className="p-4 bg-gray-50 rounded-lg">
                  <div className="text-3xl font-bold text-primary-600 mb-2">$200K</div>
                  <div className="text-gray-600">Self-funded</div>
                  <p className="mt-4 text-gray-600">
                    Used for initial development, MVP launch, and market validation
                  </p>
                </div>
              </div>

              <div>
                <h3 className="text-xl font-bold mb-4">Key Milestones</h3>
                <ul className="space-y-3">
                  {[
                    'Complete mobile app development',
                    'Expand to 5 key markets',
                    'Reach 100K monthly active users',
                    'Launch premium hotel partnerships',
                    'Achieve $1M ARR'
                  ].map((milestone, index) => (
                    <li key={index} className="flex items-center gap-2">
                      <span className="w-2 h-2 bg-primary-600 rounded-full" />
                      <span className="text-gray-600">{milestone}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </Slide>
  );
};