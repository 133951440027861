// src/components/layout/Footer.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '@/config/paths';

interface FooterLinkProps {
  to: string;
  children: React.ReactNode;
  external?: boolean;
}

const FooterLink: React.FC<FooterLinkProps> = ({ to, children, external }) => {
  if (external) {
    return (
      <a 
        href={to} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="hover:text-gray-300 transition-colors"
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={to} className="hover:text-gray-300 transition-colors">
      {children}
    </Link>
  );
};

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-800 text-white py-12">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-8">
          {/* Information Section */}
          <div>
            <h4 className="font-bold mb-4">Information</h4>
            <ul className="space-y-2">
              <li>
                {/* <FooterLink to={ROUTES.FOR_TRAVELERS}>*/}
                <FooterLink to={ROUTES.CONTACT}>
                  For Travelers
                </FooterLink>
              </li>
              <li>
                {/* <FooterLink to={ROUTES.FOR_HOTELS}>*/}
                <FooterLink to={ROUTES.CONTACT}>
                  For Hotels
                </FooterLink>
              </li>
              <li>
                {/* <FooterLink to={ROUTES.FOR_BLOGGERS}>*/}
                <FooterLink to={ROUTES.CONTACT}>
                  For Travel Bloggers
                </FooterLink>
              </li>
            </ul>
          </div>

          {/* Contact Section */}
          <div>
            <h4 className="font-bold mb-4">Contact</h4>
            <ul className="space-y-2">
              <li>
                <FooterLink to={ROUTES.CONTACT}>
                  Contact Us
                </FooterLink>
              </li>
            </ul>
          </div>

          {/* Legal Section */}
          <div>
            <h4 className="font-bold mb-4">Legal</h4>
            <ul className="space-y-2">
              <li>
                <FooterLink to={ROUTES.PRIVACY}>
                  Privacy Policy
                </FooterLink>
              </li>
              <li>
                <FooterLink to={ROUTES.TERMS}>
                  Terms of Service
                </FooterLink>
              </li>
              <li>
                <FooterLink to={ROUTES.COOKIES}>
                  Cookie Policy
                </FooterLink>
              </li>
              <li>
                <FooterLink to={ROUTES.DISCLAIMER}>
                  Disclaimer
                </FooterLink>
              </li>
              <li>
                <FooterLink to={ROUTES.AFFILIATE_DISCLOSURE}>
                  Affiliate Disclosure
                </FooterLink>
              </li>
              <li>
                <FooterLink to={ROUTES.COMMENT_POLICY}>
                  Comment Policy
                </FooterLink>
              </li>
              <li>
                <FooterLink to={ROUTES.REFUND_POLICY}>
                  Refund Policy
                </FooterLink>
              </li>
            </ul>
          </div>

          {/* Social & Contact Info */}
          <div>
            <h4 className="font-bold mb-4">Connect with Us</h4>
            <div className="space-y-4">
              <div className="space-x-4">
                <FooterLink
                  to="https://linkedin.com/company/hotelsrating"
                  external
                >
                  LinkedIn
                </FooterLink>
                <FooterLink
                  to="https://facebook.com/hotelsrating"
                  external
                >
                  Facebook
                </FooterLink>
                <FooterLink
                  to="https://youtube.com/@hotelsrating"
                  external
                >
                  YouTube
                </FooterLink>
              </div>
              <div className="text-sm space-y-2">
                <p>Email: contact@hotelsrating.com</p>
                <p>© {currentYear} HotelsRating. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;