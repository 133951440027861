// src/components/PitchDeck/components/Slide.tsx
import React from 'react';
import type { SlideProps } from '../types';

export const Slide: React.FC<SlideProps> = ({ 
  title,
  children, 
  className = "", 
  fullscreen = false,
}) => (
  <div 
    className={`min-h-screen flex flex-col justify-center p-8 slide ${className}`}
  >
    {title && <h2 className="text-4xl font-bold mb-8">{title}</h2>}
    <div className={fullscreen ? "flex-1" : "w-full max-w-6xl mx-auto"}>
      {children}
    </div>
  </div>
);