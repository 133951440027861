// src/components/PitchDeck/components/slides/CompetitiveSlide.tsx
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Slide } from '../Slide';
import { Shield, Users, Brain, Star } from 'lucide-react';

const competitiveAdvantages = [
  { 
    icon: Shield, 
    title: 'Credibility', 
    description: 'Verified video reviews influence dynamic ratings'
  },
  { 
    icon: Users, 
    title: 'Community', 
    description: 'Strong engagement and reward system'
  },
  { 
    icon: Brain, 
    title: 'AI Technology', 
    description: 'Advanced personalization algorithms'
  },
  { 
    icon: Star, 
    title: 'Dynamic Ratings', 
    description: 'Real-time updates based on fresh data'
  }
];

const comparisonMetrics = [
  { name: 'Personalization', us: 95, others: 45 },
  { name: 'Video Content', us: 100, others: 30 },
  { name: 'Real-time Updates', us: 90, others: 40 },
  { name: 'User Rewards', us: 100, others: 20 }
];

export const CompetitiveSlide: React.FC = () => (
  <Slide title="Competitive Advantage">
    <div className="grid md:grid-cols-2 gap-8">
      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-bold mb-4">Why We Stand Out</h3>
          <div className="space-y-4">
            {competitiveAdvantages.map((advantage, index) => (
              <div key={index} className="flex items-start gap-3">
                <advantage.icon className="w-6 h-6 text-primary-600 flex-shrink-0" />
                <div>
                  <span className="font-semibold">{advantage.title}</span>
                  <p className="text-gray-600">{advantage.description}</p>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-bold mb-4">Market Comparison</h3>
          <div className="space-y-4">
            {comparisonMetrics.map((item, index) => (
              <div key={index}>
                <div className="flex justify-between mb-1">
                  <span>{item.name}</span>
                </div>
                <div className="relative h-4 bg-gray-200 rounded-full">
                  <div 
                    className="absolute left-0 h-4 bg-primary-600 rounded-l-full z-20" 
                    style={{width: `${item.us}%`}} 
                  />
                  <div 
                    className="absolute left-0 h-4 bg-gray-400 rounded-l-full z-10" 
                    style={{width: `${item.others}%`}} 
                  />
                </div>
                <div className="flex justify-between text-sm mt-1">
                  <span className="text-primary-600">Us: {item.us}%</span>
                  <span className="text-gray-500">Others: {item.others}%</span>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <Card className="md:col-span-2">
        <CardContent className="p-6">
          <h3 className="text-xl font-bold mb-4">Competitive Moat</h3>
          <div className="grid md:grid-cols-3 gap-6">
            {[
              {
                title: 'Proprietary AI',
                description: 'Advanced learning algorithms for personalization'
              },
              {
                title: 'Network Effect',
                description: 'Growing community of travelers and content creators'
              },
              {
                title: 'Data Advantage',
                description: 'Rich dataset of user preferences and behaviors'
              }
            ].map((item, index) => (
              <div key={index} className="text-center p-4 bg-gray-50 rounded-lg">
                <h4 className="font-semibold mb-2">{item.title}</h4>
                <p className="text-gray-600 text-sm">{item.description}</p>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  </Slide>
);