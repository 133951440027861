// src/pages/category/CategoryPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Loader2, Filter, MapPin, Star } from 'lucide-react';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import HotelCard from '@/components/hotels/HotelCard';
import { getAllCategories } from '@/config/categories';
import hotelService from '@/services/api/hotels/HotelService';
import type { 
  HotelCategory, 
  HotelPreview, 
  CategoryId,
  LocationType 
} from '@/types/hotelCategories';
import NotFound from '@/components/shared/NotFound';

interface SortOption {
  value: string;
  label: string;
}

interface Filters {
  price: string;
  rating: Set<string>;
  locations: Set<LocationType>;
  amenities: Set<string>;
}

const sortOptions: SortOption[] = [
  { value: 'recommended', label: 'Recommended' },
  { value: 'price-asc', label: 'Price: Low to High' },
  { value: 'price-desc', label: 'Price: High to Low' },
  { value: 'rating-desc', label: 'Rating: High to Low' }
];

const priceRanges = [
  { value: 'budget', label: 'Budget', range: [0, 100] },
  { value: 'mid', label: 'Mid-Range', range: [101, 300] },
  { value: 'luxury', label: 'Luxury', range: [301, 1000] }
];

const ratingOptions = ['5', '4', '3'];

const amenityOptions = [
  'wifi',
  'pool',
  'spa',
  'restaurant',
  'fitness-center',
  'room-service'
];

const CategoryPage: React.FC = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const navigate = useNavigate();
  const [category, setCategory] = useState<HotelCategory | null>(null);
  const [hotels, setHotels] = useState<HotelPreview[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortBy, setSortBy] = useState<string>('recommended');
  const [filters, setFilters] = useState<Filters>({
    price: '',
    rating: new Set<string>(),
    locations: new Set<LocationType>(),
    amenities: new Set<string>()
  });

  useEffect(() => {
    const initializeCategory = () => {
      if (!categoryId) {
        navigate('/');
        return;
      }

      const categories = getAllCategories();
      const foundCategory = categories.find(cat => cat.id === categoryId);

      console.log('Searching category:', categoryId);
      console.log('Found category:', foundCategory);

      if (foundCategory) {
        setCategory(foundCategory);
        loadHotels(foundCategory.id, 1);
      } else {
        setError('Category not found');
        setIsLoading(false);
      }
    };

    initializeCategory();
  }, [categoryId, navigate]);

  const loadHotels = async (catId: string, pageNum: number) => {
    try {
      setIsLoading(true);
      const result = await hotelService.getHotelsByCategory(catId, {
        page: pageNum,
        limit: 12,
        sortBy,
        filters: {
          priceRange: filters.price ? 
            priceRanges.find(range => range.value === filters.price)?.range : undefined,
          rating: Array.from(filters.rating),
          locations: Array.from(filters.locations),
          amenities: Array.from(filters.amenities)
        }
      });

      if (pageNum === 1) {
        setHotels(result);
      } else {
        setHotels(prev => [...prev, ...result]);
      }

      setHasMore(result.length === 12);
      setPage(pageNum);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load hotels');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSortChange = (value: string) => {
    setSortBy(value);
    if (category) {
      loadHotels(category.id, 1);
    }
  };

  const handleFilterChange = (
    filterType: keyof Filters, 
    value: string
  ) => {
    setFilters(prev => {
      const newFilters = { ...prev };
      if (filterType === 'price') {
        newFilters.price = prev.price === value ? '' : value;
      } else {
        const filterSet = new Set(prev[filterType]);
        if (filterSet.has(value as never)) {
          filterSet.delete(value as never);
        } else {
          filterSet.add(value as never);
        }
        newFilters[filterType] = filterSet;
      }
      return newFilters;
    });

    if (category) {
      loadHotels(category.id, 1);
    }
  };

  const clearFilters = () => {
    setFilters({
      price: '',
      rating: new Set(),
      locations: new Set(),
      amenities: new Set()
    });
    setSortBy('recommended');
    if (category) {
      loadHotels(category.id, 1);
    }
  };

  const loadMore = () => {
    if (!isLoading && hasMore && category) {
      loadHotels(category.id, page + 1);
    }
  };

  if (!categoryId) {
    return <NotFound />;
  }

  if (error) {
    return <NotFound message={error} />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      {category && (
        <div className="relative h-[40vh] min-h-[300px]">
          <div className="absolute inset-0">
            <img
              src={category.imageUrl || `/images/presets/${categoryId}.webp`}
              alt={category.name}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black/50" />
          </div>
          <div className="relative container mx-auto px-4 h-full">
            <div className="flex flex-col justify-end h-full pb-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-white space-y-4"
              >
                <h1 className="text-4xl md:text-5xl font-bold">{category.name}</h1>
                <p className="text-lg text-gray-200 max-w-2xl">
                  {category.description}
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      )}

      {/* Filters and Sort Section */}
      <div className="sticky top-0 z-10 bg-white shadow-sm">
        <div className="container mx-auto px-4">
          <div className="py-4 space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                {/* Price Filter */}
                <Select
                  value={filters.price}
                  onValueChange={(value) => handleFilterChange('price', value)}
                >
                  <SelectTrigger className="w-[140px]">
                    <SelectValue placeholder="Price Range" />
                  </SelectTrigger>
                  <SelectContent>
                    {priceRanges.map((range) => (
                      <SelectItem key={range.value} value={range.value}>
                        {range.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                {/* Rating Filter */}
                <div className="flex gap-2">
                  {ratingOptions.map((rating) => (
                    <Button
                      key={rating}
                      variant={filters.rating.has(rating) ? "default" : "outline"}
                      size="sm"
                      onClick={() => handleFilterChange('rating', rating)}
                    >
                      {rating}<Star className="w-4 h-4 ml-1" />
                    </Button>
                  ))}
                </div>
              </div>

              {/* Sort Options */}
              <Select
                value={sortBy}
                onValueChange={handleSortChange}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Sort by" />
                </SelectTrigger>
                <SelectContent>
                  {sortOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            {/* Location Types */}
            {category?.criteria?.locationTypes && category.criteria.locationTypes.length > 0 && (
              <div className="flex gap-2 flex-wrap">
                {category.criteria.locationTypes.map((locationType) => (
                  <Button
                    key={locationType}
                    variant={filters.locations.has(locationType) ? "default" : "outline"}
                    size="sm"
                    onClick={() => handleFilterChange('locations', locationType)}
                  >
                    <MapPin className="w-4 h-4 mr-2" />
                    {locationType.replace(/_/g, ' ')}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-8">
        {isLoading && page === 1 ? (
          <div className="flex justify-center py-20">
            <Loader2 className="w-8 h-8 animate-spin text-primary" />
          </div>
        ) : hotels.length > 0 ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {hotels.map((hotel) => (
                <motion.div
                  key={hotel.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <HotelCard hotel={hotel} />
                </motion.div>
              ))}
            </div>
            
            {isLoading && page > 1 && (
              <div className="flex justify-center mt-8">
                <Loader2 className="w-8 h-8 animate-spin text-primary" />
              </div>
            )}

            {!isLoading && hasMore && (
              <div className="flex justify-center mt-8">
                <Button onClick={loadMore} variant="outline" size="lg">
                  Load More Hotels
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className="text-center py-20">
            <h2 className="text-2xl font-semibold text-gray-600">
              No hotels found
            </h2>
            <p className="mt-2 text-gray-500">
              {Object.values(filters).some(f => 
                f instanceof Set ? f.size > 0 : f !== ''
              ) 
                ? 'Try adjusting your filters'
                : 'No hotels available in this category yet'
              }
            </p>
            {Object.values(filters).some(f => 
              f instanceof Set ? f.size > 0 : f !== ''
            ) && (
              <Button
                onClick={clearFilters}
                variant="outline"
                className="mt-4"
              >
                Clear All Filters
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryPage;