// src/components/PitchDeck/components/slides/ProblemSlide.tsx
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Slide } from '../Slide';

const problems = [
  { 
    title: 'Static and Generic', 
    description: 'Current ratings fail to reflect real-time experiences'
  },
  { 
    title: 'Lack of Personalization', 
    description: 'Travelers struggle to find matching preferences'
  },
  { 
    title: 'Unverified Information', 
    description: 'Outdated reviews lead to trust issues'
  }
];

const marketDemands = [
  { metric: '75%', label: 'Want personalized recommendations' },
  { metric: '68%', label: 'Prefer dynamic information' },
  { metric: '83%', label: 'Trust video reviews over photos' }
];

export const ProblemSlide: React.FC = () => (
  <Slide title="The Problem">
    <div className="grid md:grid-cols-2 gap-8">
      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-bold mb-4">Current Challenges</h3>
          <ul className="space-y-4">
            {problems.map((problem, index) => (
              <li key={index} className="flex items-start gap-2">
                <span className="text-red-500">●</span>
                <div>
                  <strong>{problem.title}:</strong>
                  <p className="text-gray-600">{problem.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>

      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-bold mb-4">Market Demands</h3>
          {marketDemands.map((demand, index) => (
            <div key={index} className="mb-6">
              <div className="flex justify-between mb-2">
                <span>{demand.label}</span>
                <span className="font-bold text-primary-600">{demand.metric}</span>
              </div>
              <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-primary-600 transition-all duration-1000"
                  style={{ width: demand.metric }}
                />
              </div>
            </div>
          ))}
        </CardContent>
      </Card>
    </div>
  </Slide>
);