// src/services/db/HotelDatabaseService.ts
import axios from 'axios';

export interface HotelStats {
 totalHotels: number;
 ratingDistribution: {
   rating: string;
   count: number;
   percentage: number;
 }[];
 countriesCount: number;
 citiesCount: number;
}

export interface HotelFilters {
 searchQuery?: string;
 rating?: string[];
 cityId?: number;
 countryCode?: string;
 limit?: number;
 offset?: number;
 sortBy?: string;  
 sortDirection?: 'ASC' | 'DESC';
}

export interface HotelData {
 id: number;
 hotel_code: string;
 name: string;
 rating: string;
 city_name?: string;
 country_name?: string;
 address?: string;
 website_url?: string;
 description?: string;
 created_at: Date;
}

class HotelDatabaseService {
 private readonly API_URL = '/api/database';

 async getHotelStats(): Promise<HotelStats> {
   try {
     const response = await axios.get(`${this.API_URL}/stats`);
     return response.data;
   } catch (error) {
     console.error('Error fetching hotel stats:', error);
     // Используем мок-данные при ошибке
     return this.getMockHotelStats();
   }
 }

 async getHotels(filters: HotelFilters): Promise<{ data: HotelData[]; total: number }> {
   try {
     const response = await axios.get(`${this.API_URL}/hotels`, {
       params: filters
     });
     return response.data;
   } catch (error) {
     console.error('Error fetching hotels:', error);
     return this.getMockHotels();
   }
 }

 async getCountries(): Promise<Array<{ code: string; name: string }>> {
   try {
     const response = await axios.get(`${this.API_URL}/countries`);
     return response.data;
   } catch (error) {
     console.error('Error fetching countries:', error);
     return [
       { code: 'AE', name: 'United Arab Emirates' },
       { code: 'GB', name: 'United Kingdom' },
       { code: 'US', name: 'United States' },
       { code: 'FR', name: 'France' },
       { code: 'DE', name: 'Germany' }
     ];
   }
 }

 async getCities(searchQuery: string): Promise<Array<{ id: number; name: string; country_name: string }>> {
   try {
     const response = await axios.get(`${this.API_URL}/cities`, {
       params: { searchQuery }
     });
     return response.data;
   } catch (error) {
     console.error('Error fetching cities:', error);
     return [
       { id: 1, name: 'Dubai', country_name: 'United Arab Emirates' },
       { id: 2, name: 'Abu Dhabi', country_name: 'United Arab Emirates' },
       { id: 3, name: 'London', country_name: 'United Kingdom' },
       { id: 4, name: 'New York', country_name: 'United States' },
       { id: 5, name: 'Paris', country_name: 'France' }
     ];
   }
 }

 async updateHotel(id: number, data: Partial<HotelData>): Promise<HotelData> {
   try {
     const response = await axios.put(`${this.API_URL}/hotels/${id}`, data);
     return response.data;
   } catch (error) {
     console.error('Error updating hotel:', error);
     throw error;
   }
 }

 async deleteHotel(id: number): Promise<void> {
   try {
     await axios.delete(`${this.API_URL}/hotels/${id}`);
   } catch (error) {
     console.error('Error deleting hotel:', error);
     throw error;
   }
 }

 async getHotelDetails(id: number): Promise<HotelData> {
   try {
     const response = await axios.get(`${this.API_URL}/hotels/${id}`);
     return response.data;
   } catch (error) {
     console.error('Error fetching hotel details:', error);
     throw error;
   }
 }

 // Мок-методы для тестирования UI
 private getMockHotelStats(): HotelStats {
   return {
     totalHotels: 1000000,
     ratingDistribution: [
       { rating: '5', count: 150000, percentage: 15 },
       { rating: '4', count: 350000, percentage: 35 },
       { rating: '3', count: 300000, percentage: 30 },
       { rating: '2', count: 150000, percentage: 15 },
       { rating: '1', count: 50000, percentage: 5 }
     ],
     countriesCount: 150,
     citiesCount: 5000
   };
 }

 private getMockHotels(): { data: HotelData[]; total: number } {
   const mockHotels: HotelData[] = Array.from({ length: 10 }).map((_, index) => ({
     id: index + 1,
     hotel_code: `HTL${(index + 1).toString().padStart(6, '0')}`,
     name: `Hotel ${index + 1}`,
     rating: Math.floor(Math.random() * 5 + 1).toString(),
     city_name: 'Dubai',
     country_name: 'United Arab Emirates',
     address: `${index + 1} Sheikh Zayed Road`,
     website_url: 'https://example.com',
     description: 'A luxury hotel in the heart of Dubai',
     created_at: new Date()
   }));

   return {
     data: mockHotels,
     total: 1000
   };
 }
}

export default new HotelDatabaseService();