// src/components/common/VideoBackground.tsx
import React, { useEffect, useRef, useState } from 'react';
import { Loader2 } from 'lucide-react';

interface VideoBackgroundProps {
  src: string;
  mobileSrc: string;
  lowQualitySrc: string;
  fallbackImage: string;
  poster: string;
}

const VideoBackground: React.FC<VideoBackgroundProps> = ({
  src,
  mobileSrc,
  lowQualitySrc,
  fallbackImage,
  poster
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [currentSrc, setCurrentSrc] = useState(src);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleLoadStart = () => {
      console.log('Video load started');
      setIsLoading(true);
    };

    const handleLoadedMetadata = () => {
      console.log('Video metadata loaded');
    };

    const handleLoadedData = () => {
      console.log('Video data loaded');
      setIsLoading(false);
      video.play().catch(err => {
        console.error('Play error:', err);
        setHasError(true);
      });
    };

    const handleError = (e: Event) => {
      console.error('Video error:', (e.target as HTMLVideoElement).error);
      if (currentSrc === src) {
        setCurrentSrc(mobileSrc);
      } else if (currentSrc === mobileSrc) {
        setCurrentSrc(lowQualitySrc);
      } else {
        setHasError(true);
      }
    };

    const handleEnded = () => {
      console.log('Video ended');
      // Перезапускаем видео
      video.currentTime = 0;
      video.play().catch(console.error);
    };

    const handleProgress = () => {
      if (video.buffered.length > 0) {
        const bufferedEnd = video.buffered.end(0);
        const duration = video.duration;
        const progress = (bufferedEnd / duration) * 100;
        console.log(`Loading progress: ${progress.toFixed(2)}%`);
      }
    };

    video.addEventListener('loadstart', handleLoadStart);
    video.addEventListener('loadedmetadata', handleLoadedMetadata);
    video.addEventListener('loadeddata', handleLoadedData);
    video.addEventListener('error', handleError);
    video.addEventListener('ended', handleEnded);
    video.addEventListener('progress', handleProgress);

    return () => {
      video.removeEventListener('loadstart', handleLoadStart);
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      video.removeEventListener('loadeddata', handleLoadedData);
      video.removeEventListener('error', handleError);
      video.removeEventListener('ended', handleEnded);
      video.removeEventListener('progress', handleProgress);
    };
  }, [currentSrc, src, mobileSrc, lowQualitySrc]);

  if (hasError) {
    return (
      <div className="absolute inset-0">
        <img
          src={fallbackImage}
          alt="Background"
          className="w-full h-full object-cover"
        />
      </div>
    );
  }

  return (
    <div className="absolute inset-0">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-900">
          <Loader2 className="w-8 h-8 text-primary-500 animate-spin" />
        </div>
      )}
      <video
        ref={videoRef}
        className="w-full h-full object-cover"
        playsInline
        autoPlay
        muted
        loop
        poster={poster}
        preload="auto"
      >
        <source src={currentSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoBackground;