// src/components/PitchDeck/components/slides/MarketSlide.tsx
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Slide } from '../Slide';
import { TrendingUp } from 'lucide-react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';

const marketData = {
  tam: { value: 570, label: 'Global Travel & Hospitality Industry' },
  sam: { value: 12, label: 'Online Hotel Booking Platforms' },
  som: { value: 0.15, label: 'Initial Target Market' }
};

const revenueData = [
  { year: 'Year 1', value: 0.5 },
  { year: 'Year 2', value: 3 },
  { year: 'Year 3', value: 8 }
];

const insights = [
  {
    title: 'Growing Market',
    description: 'Online hotel booking market is expected to reach $20B by 2025'
  },
  {
    title: 'Video Content Demand',
    description: '78% of travelers watch videos before booking'
  },
  {
    title: 'AI Adoption',
    description: '65% prefer personalized recommendations'
  }
];

export const MarketSlide: React.FC = () => (
  <Slide title="Market Opportunity">
    <div className="space-y-8">
      <div className="grid md:grid-cols-3 gap-8">
        {[
          { title: 'TAM', ...marketData.tam },
          { title: 'SAM', ...marketData.sam },
          { title: 'SOM', ...marketData.som }
        ].map((item, index) => (
          <Card key={index}>
            <CardContent className="p-6 text-center">
              <h3 className="text-xl font-bold mb-2">{item.title}</h3>
              <p className="text-4xl font-bold text-primary-600">
                ${item.value}B
              </p>
              <p className="text-gray-600">{item.label}</p>
            </CardContent>
          </Card>
        ))}
      </div>

      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-bold mb-4">Market Growth</h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={revenueData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis />
                <Tooltip />
                <Line 
                  type="monotone" 
                  dataKey="value" 
                  stroke="#0ea5e9" 
                  name="Revenue ($M)" 
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <p className="text-sm text-gray-500 mt-4">
            Sources: Allied Market Research, Statista
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-bold mb-4">Market Insights</h3>
          <div className="space-y-4">
            {insights.map((insight, index) => (
              <div key={index} className="flex items-start gap-3">
                <TrendingUp className="w-6 h-6 text-primary-600 flex-shrink-0" />
                <div>
                  <span className="font-semibold">{insight.title}</span>
                  <p className="text-gray-600">{insight.description}</p>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  </Slide>
);