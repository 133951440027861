// src/pages/admin/hotels/AdminHotelsPage.tsx
import React, { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { 
 Hotel,
 Search,
 Filter,
 Star,
 Globe,
 MapPin,
 Plus,
 MoreHorizontal,
 ExternalLink,
 Edit,
 Trash2,
 Download
} from 'lucide-react';
import { 
 Card, 
 CardContent, 
 CardHeader, 
 CardTitle 
} from '@/components/ui/card';
import {
 Select,
 SelectContent,
 SelectItem,
 SelectTrigger,
 SelectValue,
} from '@/components/ui/select';
import {
 DropdownMenu,
 DropdownMenuContent,
 DropdownMenuItem,
 DropdownMenuLabel,
 DropdownMenuSeparator,
 DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
 Table,
 TableBody,
 TableCell,
 TableHead,
 TableHeader,
 TableRow,
} from "@/components/ui/table";
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Badge } from '@/components/ui/badge';
import {
 Dialog,
 DialogContent,
 DialogDescription,
 DialogHeader,
 DialogTitle,
 DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from '@/hooks/useToast';
import hotelDatabaseService, { HotelData, HotelFilters } from '@/services/db/HotelDatabaseService';

const HOTELS_PER_PAGE = 10;

const AdminHotelsPage: React.FC = () => {
 const { toast } = useToast();
 const [loading, setLoading] = useState(true);
 const [hotels, setHotels] = useState<HotelData[]>([]);
 const [totalHotels, setTotalHotels] = useState(0);
 const [countries, setCountries] = useState<Array<{ code: string; name: string }>>([]);
 const [filters, setFilters] = useState<HotelFilters>({
   searchQuery: '',
   rating: [],
   countryCode: '',
   limit: HOTELS_PER_PAGE,
   offset: 0,
   sortBy: 'name',
   sortDirection: 'ASC'
 });

 const [debouncedSearch] = useDebounce(filters.searchQuery, 500);

 useEffect(() => {
   loadCountries();
 }, []);

 useEffect(() => {
   loadHotels();
 }, [debouncedSearch, filters.rating, filters.countryCode, filters.offset, filters.sortBy, filters.sortDirection]);

 const loadCountries = async () => {
   try {
     const data = await hotelDatabaseService.getCountries();
     setCountries(data);
   } catch (error) {
     console.error('Error loading countries:', error);
     toast({
       title: 'Error',
       description: 'Failed to load countries',
       variant: 'destructive',
     });
   }
 };

 const loadHotels = async () => {
   try {
     setLoading(true);
     const { data, total } = await hotelDatabaseService.getHotels(filters);
     setHotels(data);
     setTotalHotels(total);
   } catch (error) {
     console.error('Error loading hotels:', error);
     toast({
       title: 'Error',
       description: 'Failed to load hotels',
       variant: 'destructive',
     });
   } finally {
     setLoading(false);
   }
 };

 const handleSearch = (value: string) => {
   setFilters(prev => ({ ...prev, searchQuery: value, offset: 0 }));
 };

 const handleRatingChange = (value: string[]) => {
   setFilters(prev => ({ ...prev, rating: value, offset: 0 }));
 };

 const handleCountryChange = (value: string) => {
   setFilters(prev => ({ ...prev, countryCode: value, offset: 0 }));
 };

 const handleSort = (column: string) => {
   setFilters(prev => ({
     ...prev,
     sortBy: column,
     sortDirection: prev.sortBy === column && prev.sortDirection === 'ASC' ? 'DESC' : 'ASC'
   }));
 };

 const handlePageChange = (newPage: number) => {
   setFilters(prev => ({
     ...prev,
     offset: (newPage - 1) * HOTELS_PER_PAGE
   }));
 };

 const handleExport = async () => {
   toast({
     title: 'Coming Soon',
     description: 'Export functionality will be available soon',
   });
 };

 const renderRatingStars = (rating: string) => {
   return (
     <div className="flex items-center gap-1">
       <span>{rating}</span>
       <Star className="h-4 w-4 text-yellow-500 fill-yellow-500" />
     </div>
   );
 };

 return (
   <div className="container mx-auto p-6">
     <Card>
       <CardHeader className="flex flex-row items-center justify-between">
         <CardTitle className="flex items-center gap-2">
           <Hotel className="h-5 w-5" />
           Hotels Database
         </CardTitle>
         <div className="flex items-center gap-2">
           <Button variant="outline" onClick={handleExport}>
             <Download className="h-4 w-4 mr-2" />
             Export
           </Button>
           <Dialog>
             <DialogTrigger asChild>
               <Button>
                 <Plus className="h-4 w-4 mr-2" />
                 Add Hotel
               </Button>
             </DialogTrigger>
             <DialogContent>
               <DialogHeader>
                 <DialogTitle>Add New Hotel</DialogTitle>
                 <DialogDescription>
                   Add a new hotel to the database.
                 </DialogDescription>
               </DialogHeader>
               {/* TODO: Add hotel form */}
             </DialogContent>
           </Dialog>
         </div>
       </CardHeader>
       <CardContent>
         {/* Filters */}
         <div className="mb-6 space-y-4">
           <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
             <div className="relative">
               <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
               <Input
                 placeholder="Search hotels..."
                 value={filters.searchQuery}
                 onChange={(e) => handleSearch(e.target.value)}
                 className="pl-9"
               />
             </div>
             <Select
               value={filters.countryCode}
               onValueChange={handleCountryChange}
             >
               <SelectTrigger>
                 <SelectValue placeholder="Select country" />
               </SelectTrigger>
               <SelectContent>
                 <SelectItem value="">All Countries</SelectItem>
                 {countries.map(country => (
                   <SelectItem key={country.code} value={country.code}>
                     {country.name}
                   </SelectItem>
                 ))}
               </SelectContent>
             </Select>
             <Select
               value={filters.rating?.join(',')}
               onValueChange={(value) => handleRatingChange(value ? value.split(',') : [])}
             >
               <SelectTrigger>
                 <SelectValue placeholder="Select rating" />
               </SelectTrigger>
               <SelectContent>
                 {['1', '2', '3', '4', '5'].map(rating => (
                   <SelectItem key={rating} value={rating}>
                     {renderRatingStars(rating)}
                   </SelectItem>
                 ))}
               </SelectContent>
             </Select>
           </div>
         </div>

         {/* Hotels Table */}
         <div className="rounded-md border">
           <Table>
             <TableHeader>
               <TableRow>
                 <TableHead onClick={() => handleSort('hotel_code')} className="cursor-pointer hover:bg-gray-50">
                   Code {filters.sortBy === 'hotel_code' && (filters.sortDirection === 'ASC' ? '↑' : '↓')}
                 </TableHead>
                 <TableHead onClick={() => handleSort('name')} className="cursor-pointer hover:bg-gray-50">
                   Name {filters.sortBy === 'name' && (filters.sortDirection === 'ASC' ? '↑' : '↓')}
                 </TableHead>
                 <TableHead onClick={() => handleSort('rating')} className="cursor-pointer hover:bg-gray-50">
                   Rating {filters.sortBy === 'rating' && (filters.sortDirection === 'ASC' ? '↑' : '↓')}
                 </TableHead>
                 <TableHead>Location</TableHead>
                 <TableHead>Website</TableHead>
                 <TableHead className="w-[100px]">Actions</TableHead>
               </TableRow>
             </TableHeader>
             <TableBody>
               {loading ? (
                 <TableRow>
                   <TableCell colSpan={6} className="text-center py-10">
                     <div className="flex justify-center">
                       <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900" />
                     </div>
                   </TableCell>
                 </TableRow>
               ) : hotels.length === 0 ? (
                 <TableRow>
                   <TableCell colSpan={6} className="text-center py-10">
                     No hotels found
                   </TableCell>
                 </TableRow>
               ) : (
                 hotels.map((hotel) => (
                   <TableRow key={hotel.id}>
                     <TableCell className="font-medium">{hotel.hotel_code}</TableCell>
                     <TableCell>{hotel.name}</TableCell>
                     <TableCell>{renderRatingStars(hotel.rating)}</TableCell>
                     <TableCell>
                       <div className="flex items-start gap-1 text-sm">
                         <MapPin className="h-4 w-4 text-gray-500 mt-0.5" />
                         <div>
                           {hotel.city_name && <div>{hotel.city_name}</div>}
                           {hotel.country_name && <div className="text-gray-500">{hotel.country_name}</div>}
                         </div>
                       </div>
                     </TableCell>
                     <TableCell>
                       {hotel.website_url && (
                        <a 
                           href={hotel.website_url}
                           target="_blank"
                           rel="noopener noreferrer"
                           className="text-primary hover:underline inline-flex items-center"
                         >
                           Visit site
                           <ExternalLink className="h-3 w-3 ml-1" />
                         </a>
                       )} 
                     </TableCell>
                     <TableCell>
                       <DropdownMenu>
                         <DropdownMenuTrigger asChild>
                           <Button variant="ghost" className="h-8 w-8 p-0">
                             <MoreHorizontal className="h-4 w-4" />
                           </Button>
                         </DropdownMenuTrigger>
                         <DropdownMenuContent align="end">
                           <DropdownMenuLabel>Actions</DropdownMenuLabel>
                           <DropdownMenuItem>
                             <Edit className="h-4 w-4 mr-2" />
                             Edit
                           </DropdownMenuItem>
                           <DropdownMenuItem>
                             <ExternalLink className="h-4 w-4 mr-2" />
                             View Details
                           </DropdownMenuItem>
                           <DropdownMenuSeparator />
                           <DropdownMenuItem className="text-red-600">
                             <Trash2 className="h-4 w-4 mr-2" />
                             Delete
                           </DropdownMenuItem>
                         </DropdownMenuContent>
                       </DropdownMenu>
                     </TableCell>
                   </TableRow>
                 ))
               )}
             </TableBody>
           </Table>
         </div>

         {/* Pagination */}
         <div className="flex items-center justify-between mt-4">
           <div className="text-sm text-gray-500">
             Showing {hotels.length} of {totalHotels} hotels
           </div>
           <div className="flex items-center gap-2">
             <Button
               variant="outline"
               onClick={() => handlePageChange(Math.ceil(filters.offset! / HOTELS_PER_PAGE))}
               disabled={filters.offset === 0}
             >
               Previous
             </Button>
             <Button
               variant="outline"
               onClick={() => handlePageChange(Math.ceil(filters.offset! / HOTELS_PER_PAGE) + 2)}
               disabled={(filters.offset! + HOTELS_PER_PAGE) >= totalHotels}
             >
               Next
             </Button>
           </div>
         </div>
       </CardContent>
     </Card>
   </div>
 );
};

export default AdminHotelsPage;