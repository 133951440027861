// src/components/contact/ContactFormMain.tsx
import React, { useState } from 'react';
import { 
  Mail, 
  User, 
  Send,
  HelpCircle,
  Flag,
  Star,
  Bug,
  Coffee,
  MessageSquare
} from 'lucide-react';
import { useToast } from '@/hooks/useToast';
import { useAuth } from '@/contexts/AuthContext';
import contactService from '@/services/contactService';

interface InquiryType {
  value: string;
  label: string;
  icon: React.FC<{ className?: string }>;
  description: string;
}

interface FormData {
  name: string;
  email: string;
  type: string;
  subject: string;
  message: string;
  attachments: File[];
}

interface ContactFormMainProps {
  initialData?: {
    name?: string;
    email?: string;
  };
}

const INQUIRY_TYPES: Record<string, InquiryType> = {
  GENERAL: {
    value: 'general',
    label: 'General Inquiry',
    icon: HelpCircle,
    description: 'General questions about our services'
  },
  SUPPORT: {
    value: 'support',
    label: 'Technical Support',
    icon: Bug,
    description: 'Issues with website functionality'
  },
  FEEDBACK: {
    value: 'feedback',
    label: 'Feedback & Suggestions',
    icon: MessageSquare,
    description: 'Share your ideas and feedback'
  },
  REPORT: {
    value: 'report',
    label: 'Report an Issue',
    icon: Flag,
    description: 'Report inappropriate content or behavior'
  },
  BUSINESS: {
    value: 'business',
    label: 'Business Inquiry',
    icon: Coffee,
    description: 'Partnership and business opportunities'
  },
  REVIEW: {
    value: 'review',
    label: 'Review Appeal',
    icon: Star,
    description: 'Appeal regarding a review decision'
  }
};

const ContactFormMain: React.FC<ContactFormMainProps> = ({ initialData = {} }) => {
  const { toast } = useToast();
  const { currentUser } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState<FormData>({
    name: initialData.name || '',
    email: initialData.email || '',
    type: 'general',
    subject: '',
    message: '',
    attachments: []
  });

  const [errors, setErrors] = useState<Partial<Record<keyof FormData, string>>>({});

  const validateForm = (): boolean => {
    const newErrors: Partial<Record<keyof FormData, string>> = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    if (!formData.subject.trim()) newErrors.subject = 'Subject is required';
    if (!formData.message.trim()) newErrors.message = 'Message is required';
    if (formData.message.length < 20) newErrors.message = 'Message must be at least 20 characters';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm() || isSubmitting) return;

    setIsSubmitting(true);

    try {
      const messageData = {
        ...formData,
        createdAt: new Date().toISOString(),
        status: 'new'
      };

      await contactService.submitMessage(messageData);

      toast({
        title: "Message Sent",
        description: "Thank you for your message. We'll get back to you soon!"
      });

      setFormData({
        name: currentUser?.displayName || '',
        email: currentUser?.email || '',
        type: 'general',
        subject: '',
        message: '',
        attachments: []
      });

    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to send message. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border p-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Contact Type Selection */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {Object.values(INQUIRY_TYPES).map(({ value, label, icon: Icon, description }) => (
            <div
              key={value}
              className={`
                relative p-4 rounded-lg border cursor-pointer transition-colors
                ${formData.type === value 
                  ? 'border-gray-900 bg-gray-50 text-gray-900' 
                  : 'hover:border-gray-300'
                }
              `}
              onClick={() => setFormData(prev => ({ ...prev, type: value }))}
            >
              <div className="flex items-start gap-3">
                <Icon className={`w-5 h-5 mt-0.5 ${formData.type === value ? 'text-gray-900' : 'text-gray-400'}`} />
                <div>
                  <div className="font-medium">{label}</div>
                  <div className="text-sm text-gray-500">{description}</div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Name & Email Fields */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Your Name</label>
            <div className="mt-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <User className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                className={`
                  block w-full pl-10 pr-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-900 focus:border-gray-900
                  ${errors.name ? 'border-red-500' : 'border-gray-300'}
                `}
                placeholder="John Doe"
              />
              {errors.name && (
                <div className="text-red-500 text-sm mt-1">{errors.name}</div>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Email Address</label>
            <div className="mt-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                className={`
                  block w-full pl-10 pr-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-900 focus:border-gray-900
                  ${errors.email ? 'border-red-500' : 'border-gray-300'}
                `}
                placeholder="you@example.com"
              />
              {errors.email && (
                <div className="text-red-500 text-sm mt-1">{errors.email}</div>
              )}
            </div>
          </div>
        </div>

        {/* Subject */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Subject</label>
          <input
            type="text"
            value={formData.subject}
            onChange={(e) => setFormData(prev => ({ ...prev, subject: e.target.value }))}
            className={`
              block w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-900 focus:border-gray-900
              ${errors.subject ? 'border-red-500' : 'border-gray-300'}
            `}
            placeholder="Brief description of your inquiry"
          />
          {errors.subject && (
            <div className="text-red-500 text-sm mt-1">{errors.subject}</div>
          )}
        </div>

        {/* Message */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Message</label>
          <textarea
            rows={6}
            value={formData.message}
            onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
            className={`
              block w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-900 focus:border-gray-900
              ${errors.message ? 'border-red-500' : 'border-gray-300'}
            `}
            placeholder="Please provide as much detail as possible..."
          />
          {errors.message && (
            <div className="text-red-500 text-sm mt-1">{errors.message}</div>
          )}
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isSubmitting}
            className={`
              inline-flex items-center px-6 py-3 border border-transparent rounded-lg
              text-base font-medium text-white bg-gray-900 
              hover:bg-gray-800 focus:outline-none focus:ring-2 
              focus:ring-offset-2 focus:ring-gray-900
              disabled:opacity-50 disabled:cursor-not-allowed
              transition-colors duration-200
            `}
          >
            {isSubmitting ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Sending...
              </>
            ) : (
              <>
                <Send className="w-5 h-5 mr-2" />
                Send Message
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactFormMain;