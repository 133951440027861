// src/components/layout/Navigation.tsx
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Home, 
  Video, 
  Map, 
  User, 
  Menu, 
  X, 
  Settings, 
  Search, 
  LogOut,
  Shield
} from 'lucide-react';
import Logo from '../ui/logo';
import { ROUTES } from '../../config/paths';
import { useAuth } from '../../contexts/AuthContext';
import { cn } from '@/lib/utils';

interface NavLink {
  to: string;
  icon: React.FC<{ size?: number }>;
  label: string;
}

const Navigation: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { currentUser, logout } = useAuth();
  const location = useLocation();
  const isLandingPage = location.pathname === '/';
  const isContactPage = location.pathname === ROUTES.CONTACT; 

  useEffect(() => {
    if (!isLandingPage) return;

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLandingPage]);

  const navLinks: NavLink[] = [
    { to: ROUTES.HOME, icon: Home, label: "Home" },
    { to: ROUTES.RATING, icon: Settings, label: "Personalize" },
    { to: ROUTES.REVIEWS, icon: Video, label: "Reviews" },
    { to: ROUTES.MAP, icon: Map, label: "Map" },
  ];

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const renderMobileMenu = () => (
    <div 
      className={cn(
        "md:hidden transition-all duration-300 overflow-hidden bg-white border-t",
        isMobileMenuOpen ? 'max-h-screen' : 'max-h-0'
      )}
    >
      <div className="px-4 py-2 space-y-2">
        {!isLandingPage && (
          <button
            onClick={() => setIsSearchOpen(!isSearchOpen)}
            className="w-full text-left py-2 text-gray-700 hover:text-primary-600 transition-colors duration-300"
          >
            <Search size={20} className="inline mr-2" />
            Search
          </button>
        )}
        
        {!isLandingPage && navLinks.map(({ to, icon: Icon, label }) => (
          <Link
            key={to}
            to={to}
            className="flex items-center gap-2 w-full py-2 text-gray-700 hover:text-primary-600 transition-colors duration-300"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <Icon size={20} />
            {label}
          </Link>
        ))}

        {currentUser ? (
          <>
            <Link 
              to={ROUTES.PROFILE} 
              className="flex items-center gap-2 w-full py-2 text-gray-700"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <User size={20} className="inline mr-2" />
              {currentUser.displayName || currentUser.email}
            </Link>
            {currentUser.isAdmin && (
              <Link 
                to={ROUTES.ADMIN_DASHBOARD} 
                className="flex items-center gap-2 w-full py-2 text-gray-700"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <Shield size={20} className="inline mr-2" />
                Admin Dashboard
              </Link>
            )}
            <button
              onClick={() => {
                handleLogout();
                setIsMobileMenuOpen(false);
              }}
              className="flex items-center gap-2 w-full py-2 text-red-600"
            >
              <LogOut size={20} className="inline mr-2" />
              Logout
            </button>
          </>
        ) : (
          <Link 
            to={ROUTES.LOGIN} 
            className="flex items-center gap-2 w-full py-2 text-gray-700"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <User size={20} className="inline mr-2" />
            Sign In
          </Link>
        )}
      </div>
    </div>
  );

  //ContactPage (all for now)
  if (!isLandingPage) {
    return (
      <nav 
        className="bg-white shadow-md sticky top-0 z-50"
      >
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <Link 
              to={ROUTES.HOME}
              className="flex items-center gap-3"
            >
              <Logo className="h-8 text-gray-900 hover:text-gray-800 transition-colors" />
            </Link>

            <div className="hidden md:flex items-center">
              {currentUser ? (
                <div className="flex items-center gap-4">
                  <Link
                    to={ROUTES.PROFILE}
                    className="flex items-center gap-2 text-gray-700 hover:text-gray-900 transition-colors duration-300"
                  >
                    <User size={20} />
                    <span className="truncate max-w-[120px]">
                      {currentUser.displayName || currentUser.email}
                    </span>
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="flex items-center gap-2 bg-gray-100 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-200 transition-all duration-300"
                  >
                    <LogOut size={20} />
                    <span className="hidden sm:inline">Logout</span>
                  </button>
                </div>
              ) : (
                <Link
                  to={ROUTES.LOGIN}
                  className="flex items-center gap-2 bg-gray-900 text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition-all duration-300"
                >
                  <User size={20} />
                  <span>Sign In</span>
                </Link>
              )}
            </div>

            {/* Мобильное меню для страницы контактов */}
            <button
              className="md:hidden text-gray-700"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        {/* Упрощенное мобильное меню для страницы контактов */}
        <div 
          className={cn(
            "md:hidden transition-all duration-300",
            isMobileMenuOpen ? "max-h-screen" : "max-h-0",
            "overflow-hidden bg-white border-t"
          )}
        >
          <div className="px-4 py-2 space-y-2">
            {currentUser ? (
              <>
                <Link 
                  to={ROUTES.PROFILE} 
                  className="flex items-center gap-2 w-full py-2 text-gray-700"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <User size={20} className="inline mr-2" />
                  {currentUser.displayName || currentUser.email}
                </Link>
                <button
                  onClick={() => {
                    handleLogout();
                    setIsMobileMenuOpen(false);
                  }}
                  className="flex items-center gap-2 w-full py-2 text-red-600"
                >
                  <LogOut size={20} className="inline mr-2" />
                  Logout
                </button>
              </>
            ) : (
              <Link 
                to={ROUTES.LOGIN} 
                className="flex items-center gap-2 w-full py-2 text-gray-700"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <User size={20} className="inline mr-2" />
                Sign In
              </Link>
            )}
          </div>
        </div>
      </nav>
    );
  }

  // Landing page navigation
  if (isLandingPage) {
    return (
      <nav 
        className={cn(
          "fixed top-0 left-0 right-0 z-50 transition-all duration-300",
          {
            'bg-white shadow-md': isScrolled,
            'bg-transparent': !isScrolled
          }
        )}
      >
        {!isScrolled && (
          <div className="absolute inset-0 bg-gradient-to-b from-black/50 via-black/25 to-transparent pointer-events-none" />
        )}
        
        <div className="max-w-7xl mx-auto px-4 relative">
          <div className="flex justify-between items-center h-20">
            <Link 
              to={ROUTES.HOME}
              className="flex items-center gap-3"
            >
             <Logo 
                className={cn(
                "h-8 transition-colors duration-300",
                isScrolled 
                  ? "text-black" // Меняем с text-primary-600 на text-black
                  : "text-white"
                )}
              />
            </Link>
            
            <div className="hidden md:flex items-center space-x-6">
              {currentUser ? (
                <div className="flex items-center gap-4">
                  <Link
                    to={ROUTES.PROFILE}
                    className={cn(
                      "flex items-center gap-2 transition-colors duration-300",
                      isScrolled 
                        ? "text-gray-600 hover:text-gray-900" 
                        : "text-white hover:text-gray-200"
                    )}
                  >
                    <User size={20} />
                    <span className="truncate max-w-[120px]">
                      {currentUser.displayName || currentUser.email}
                    </span>
                  </Link>
                  <button
                    onClick={handleLogout}
                    className={cn(
                      "flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-300",
                      isScrolled 
                        ? "bg-gray-900 text-white hover:bg-gray-800"
                        : "bg-white/20 text-white hover:bg-white/30 backdrop-blur-sm" 
                    )}
                  >
                    <LogOut size={20} />
                    <span>Logout</span>
                  </button>
                </div>
              ) : (
                <>
                  <Link
                    to={ROUTES.CONTACT}
                    className={cn(
                      "transition-colors duration-300",
                      isScrolled ? "text-gray-600 hover:text-primary-600" : "text-white hover:text-gray-200"
                    )}
                  >
                    Contact
                  </Link>
                  <Link
                    to={ROUTES.LOGIN}
                    className={cn(
                      "px-4 py-2 rounded-lg transition-all duration-300",
                      isScrolled
                        ? "bg-primary-600 text-white hover:bg-primary-700"
                        : "bg-black text-white hover:bg-gray-800"
                    )}
                  >
                    Sign In
                  </Link>
                </>
              )}
            </div>

            <button
              className={cn(
                "md:hidden",
                isScrolled 
                  ? "text-gray-700" 
                  : "text-white"
              )}
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        {renderMobileMenu()}
      </nav>
    );
  }

  // Regular navigation
  return (
    <nav className="bg-white shadow-md sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <Link 
            to={ROUTES.HOME}
            className="flex items-center gap-3"
          >
            <Logo className="h-8 text-primary-600 hover:text-primary-700 transition-colors" />
          </Link>

          <div className="hidden md:flex items-center space-x-8">
            <button
              onClick={() => setIsSearchOpen(!isSearchOpen)}
              className="text-gray-700 hover:text-primary-600 transition-colors duration-300"
            >
              <Search size={20} />
            </button>
            
            {navLinks.map(({ to, icon: Icon, label }) => (
              <Link
                key={to}
                to={to}
                className="flex items-center gap-2 text-gray-700 hover:text-primary-600 transition-colors duration-300"
              >
                <Icon size={20} />
                <span>{label}</span>
              </Link>
            ))}

            {currentUser ? (
              <div className="flex items-center gap-4">
                <Link
                  to={ROUTES.PROFILE}
                  className="flex items-center gap-2 text-gray-700 hover:text-primary-600 transition-colors duration-300"
                >
                  <User size={20} />
                  <span className="truncate max-w-[120px]">
                    {currentUser.displayName || currentUser.email}
                  </span>
                </Link>
                {currentUser.isAdmin && (
                  <Link
                    to={ROUTES.ADMIN_DASHBOARD}
                    className="flex items-center gap-2 text-gray-700 hover:text-primary-600 transition-colors duration-300"
                  >
                    <Shield size={20} />
                    <span>Admin</span>
                  </Link>
                )}
                <button
                  onClick={handleLogout}
                  className="flex items-center gap-2 bg-gray-100 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-200 transition-all duration-300"
                >
                  <LogOut size={20} />
                  <span className="hidden sm:inline">Logout</span>
                </button>
              </div>
            ) : (
              <Link
                to={ROUTES.LOGIN}
                className="flex items-center gap-2 bg-primary-600 text-white px-4 py-2 rounded-lg hover:bg-primary-700 transition-all duration-300 transform hover:-translate-y-0.5 hover:shadow-lg"
              >
                <User size={20} />
                <span>Sign In</span>
              </Link>
            )}
          </div>

          <button
            className="md:hidden text-gray-700"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        <div className={cn(
          "absolute left-0 w-full px-4 bg-white transition-all duration-300",
          isSearchOpen ? "top-16" : "-top-full"
        )}>
          <div className="relative max-w-2xl mx-auto py-4">
            <input
              type="text"
              placeholder="Search hotels, locations, or features..."
              className="w-full px-4 py-2 rounded-lg border focus:ring-2 focus:ring-primary-600 focus:border-primary-600 shadow-sm"
            />
            <button 
              className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-primary-600 transition-colors duration-300"
            >
              <Search size={20} />
            </button>
          </div>
        </div>
      </div>

      {renderMobileMenu()}
    </nav>
  );
};

export default Navigation;