// src/pages/information/ForInvestorsPage.tsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Briefcase, Mail } from 'lucide-react';

const ForInvestorsPage = () => {
  return (
    <>
      <Helmet>
        <title>Investment Opportunities - HOTELSRATING</title>
        <meta name="description" content="Investment opportunities in HOTELSRATING - the future of personalized hotel discovery" />
      </Helmet>

      <div className="min-h-screen bg-white py-12">
        <div className="max-w-3xl mx-auto px-4">
          <Card className="p-6 text-center">
            <Briefcase className="w-12 h-12 text-primary-600 mx-auto mb-4" />
            <h1 className="text-2xl font-bold mb-4">Investment Opportunities</h1>
            <p className="text-gray-600 mb-6">
              Thank you for your interest in HOTELSRATING. Please contact our 
              investment relations team to learn more about investment opportunities 
              and to request access to our investor materials.
            </p>
            <Button 
              onClick={() => window.location.href = 'mailto:investors@hotelsrating.com'}
              className="inline-flex items-center"
            >
              <Mail className="w-4 h-4 mr-2" />
              Contact Investment Team
            </Button>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ForInvestorsPage;