// src/components/PitchDeck/components/slides/ContactSlide.tsx
import React from 'react';
import { Slide } from '../Slide';
import { Button } from '@/components/ui/button';
import { Phone, Mail, Globe, Download, ArrowRight } from 'lucide-react';

interface ContactSlideProps {
  onPrint: () => void;
}

export const ContactSlide: React.FC<ContactSlideProps> = ({ onPrint }) => {
  return (
    <Slide className="bg-gradient-to-br from-gray-900 to-gray-800 text-white">
      <div className="text-center max-w-3xl mx-auto">
        <h2 className="text-4xl font-bold mb-8">Contact Information</h2>
        
        <div className="mb-12">
          <h3 className="text-2xl font-bold mb-2">Alexander Green</h3>
          <p className="text-xl text-gray-300">Founder & CEO</p>
        </div>

        <div className="space-y-6 mb-12">
          <p className="text-xl flex items-center justify-center gap-3 
                        hover:text-gray-300 transition-colors">
            <Phone className="w-6 h-6" />
            +971556375117
          </p>
          <a href="mailto:ag@hotelsrating.com" 
             className="text-xl flex items-center justify-center gap-3 
                        hover:text-gray-300 transition-colors">
            <Mail className="w-6 h-6" />
            ag@hotelsrating.com
          </a>
          <a href="https://www.hotelsrating.com" 
             target="_blank"
             rel="noopener noreferrer"
             className="text-xl flex items-center justify-center gap-3 
                        hover:text-gray-300 transition-colors">
            <Globe className="w-6 h-6" />
            www.hotelsrating.com
          </a>
        </div>

        <div className="space-y-6">
          <div className="text-gray-300 text-lg">
            Ready to revolutionize hotel discovery?
          </div>
          
          <div className="flex gap-4 justify-center">
            <Button 
              size="lg"
              onClick={() => onPrint()}
              className="bg-white text-gray-900 hover:bg-gray-100 
                         transition-all duration-300 transform hover:-translate-y-0.5"
            >
              <Download className="mr-2 h-5 w-5" />
              Download Pitch Deck
            </Button>

            <Button 
              size="lg"
              variant="outline"
              className="text-white border-white hover:bg-white/10 
                         transition-all duration-300 transform hover:-translate-y-0.5"
              onClick={() => window.location.href = 'mailto:ag@hotelsrating.com'}
            >
              Contact Us
              <ArrowRight className="ml-2 h-5 w-5" />
            </Button>
          </div>
        </div>

        <div className="mt-16 space-y-3">
          <div className="text-sm text-gray-400">
            © 2024 HotelsRating. All rights reserved.
          </div>
          <div className="text-xs text-gray-500">
            This presentation is confidential and proprietary.
          </div>
        </div>
      </div>
    </Slide>
  );
};