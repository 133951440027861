// src/pages/LandingPage.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { 
  ChevronRight, 
  Star, 
  Globe, 
  Users, 
  Hotel, 
  Target,
  Video,
  Zap,
  Shield,
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { ROUTES } from '@/config/paths';
import VideoBackground from '@/components/common/VideoBackground';
import Logo from '@/components/ui/logo';
import Navigation from '@/components/layout/Navigation';

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navigation /> {/* Добавляем компонент Navigation */}
      {children}
    </div>
  );
};

const LandingPage: React.FC = () => {
  return (
    <MainLayout>
      {/* Hero Section */}
      <section className="relative h-screen">
      <div className="absolute inset-0">
        <VideoBackground
          src={`${import.meta.env.VITE_VIDEO_SERVER_URL}/videos/hero.mp4`}
          mobileSrc={`${import.meta.env.VITE_VIDEO_SERVER_URL}/videos/hero-mobile.mp4`}
          lowQualitySrc={`${import.meta.env.VITE_VIDEO_SERVER_URL}/videos/hero-low.mp4`}
          fallbackImage="/images/backgrounds/hero.jpg"
          poster="/images/backgrounds/hero-poster.jpg"
        />
    <div className="absolute inset-0 bg-gradient-to-b from-black/50 via-black/50 to-black/80" />
  </div>

        <div className="relative container mx-auto px-4 h-full flex flex-col justify-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-4xl"
          >
            <h1 className="text-6xl md:text-8xl font-bold text-white mb-4">
              Hotels Rating Redefined
            </h1>
            <p className="text-xl md:text-2xl text-gray-200 mb-8 max-w-3xl">
              Discover your perfect hotel stay through personalized ratings, authentic reviews, and real-time insights.
            </p>
            <Link to={ROUTES.CONTACT}>
              <Button size="lg" className="bg-primary-500 hover:bg-primary-600">
                Contact Us <ChevronRight className="ml-2 w-5 h-5" />
              </Button>
            </Link>
          </motion.div>
        </div>
      </section>
      {/* About Section */}
      <section className="py-24 bg-gradient-to-b from-black to-gray-900 text-white">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="max-w-4xl mx-auto space-y-8"
          >
            <h2 className="text-3xl font-light leading-relaxed text-white">
              HotelsRating.com is transforming the way people choose hotels by replacing outdated, generic ratings with a dynamic, personalized system tailored to each traveler's unique preferences.
            </h2>
            
            <p className="text-xl text-gray-300 leading-relaxed">
              Our platform combines cutting-edge technology with engaging content to provide a seamless experience, helping travelers find their perfect match while giving hotels unmatched visibility and direct feedback from real guests.
            </p>

            <div className="relative mt-12">
              <div className="absolute -left-4 top-0 bottom-0 w-1 bg-gradient-to-b from-primary-400 to-primary-600"></div>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.3 }}
                className="pl-6"
              >
                <p className="text-2xl font-light text-white italic">
                  HotelsRating.com isn't just a rating system - it's a smarter, more engaging way to discover the best in hospitality.
                </p>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Logo Section */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="flex flex-col items-center justify-center"
          >
            <div className="mb-12">
              <Logo 
                className="w-[600px] h-auto" 
                aria-label="HotelsRating Logo"
                animate={true}
              />
            </div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ 
                opacity: 1, 
                y: 0,
                transition: {
                  delay: 2.5,
                  duration: 0.8,
                  ease: "easeOut"
                }
              }}
              viewport={{ once: true }}
            >
              <p className="text-2xl font-light text-gray-600 text-center max-w-3xl">
                The new standard in personalized hotel ratings, 
                where every traveler's unique preferences shape their perfect stay
              </p>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center max-w-3xl mx-auto mb-16"
          >
            <h2 className="text-4xl font-bold mb-6">Why Choose HotelsRating</h2>
            <p className="text-xl text-gray-600">
              Experience the future of hotel selection with our innovative platform
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
              >
                <Card className="h-full hover:shadow-lg transition-shadow">
                  <CardContent className="p-6">
                    <feature.icon className="w-12 h-12 text-gray-900 mb-4" />
                    <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      {/* Investment CTA Section */}
      <section className="py-20 bg-gradient-to-r from-gray-900 to-black">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center text-white">
            <h2 className="text-4xl font-bold mb-6">
              Join Us in Revolutionizing Hotel Selection
            </h2>
            <p className="text-xl mb-4">
              We're currently in our Pre-Seed stage, building the future of personalized hotel discovery.
            </p>
            <p className="text-lg text-gray-300 mb-8">
              Interested in being part of this transformation? Request our pitch deck to learn more about our vision, traction, and growth strategy.
            </p>
            <Link to={ROUTES.CONTACT}>
              <Button size="lg" className="bg-white text-gray-900 hover:bg-gray-100">
                Request Pitch Deck <ChevronRight className="ml-2 w-5 h-5" />
              </Button>
            </Link>
          </div>
        </div>
      </section>

      {/* Beta Testing Section */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
            >
              <h2 className="text-4xl font-bold mb-6 text-gray-900">
                Join Our Beta Testing Program
              </h2>
              <p className="text-xl text-gray-600 mb-4">
                Be among the first to experience the future of hotel rating.
              </p>
              <p className="text-xl text-gray-600 mb-8">
                As a beta tester, you'll get:
              </p>
              
              <div className="grid md:grid-cols-2 gap-6 mb-10 text-left">
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                  className="bg-white p-6 rounded-lg shadow-sm"
                >
                  <h3 className="text-lg font-semibold mb-3 text-primary-600">Early Access Benefits</h3>
                  <ul className="space-y-2 text-gray-600">
                    <li>• Priority access to new features</li>
                    <li>• Exclusive personalization options</li>
                    <li>• Direct influence on product development</li>
                    <li>• Special status after full release</li>
                  </ul>
                </motion.div>
                
                <motion.div
                  initial={{ opacity: 0, x: 20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.8, delay: 0.4 }}
                  className="bg-white p-6 rounded-lg shadow-sm"
                >
                  <h3 className="text-lg font-semibold mb-3 text-primary-600">What We Need</h3>
                  <ul className="space-y-2 text-gray-600">
                    <li>• Test our recommendation system</li>
                    <li>• Provide feedback on user experience</li>
                    <li>• Try new features as they roll out</li>
                    <li>• Share your travel preferences</li>
                  </ul>
                </motion.div>
              </div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: 0.6 }}
              >
                <p className="text-gray-600 mb-8">
                  Limited spots available. Join now to help shape the future of hotel discovery.
                </p>
                
                <Link to={ROUTES.CONTACT}>
                  <Button 
                    size="lg"
                    className="bg-primary-600 hover:bg-primary-700 text-white"
                  >
                    Apply for Beta Testing
                    <ChevronRight className="ml-2 w-5 h-5" />
                  </Button>
                </Link>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

const features = [
  {
    icon: Target,
    title: 'Personalized Ratings',
    description: 'Our AI-powered system learns your preferences to provide tailored hotel recommendations that perfectly match your unique travel style.'
  },
  {
    icon: Video,
    title: 'Authentic Video Reviews',
    description: 'Experience hotels through the eyes of trusted travel bloggers and real guests with our curated video review platform.'
  },
  {
    icon: Zap,
    title: 'Real-time Updates',
    description: 'Stay informed with dynamic ratings that reflect current conditions, recent reviews, and latest property updates.'
  },
  {
    icon: Globe,
    title: 'Global Coverage',
    description: 'Access detailed information and reviews for over 1 million hotels across 150+ countries worldwide.'
  },
  {
    icon: Shield,
    title: 'Trusted Platform',
    description: 'Make confident decisions with verified reviews, professional content, and transparent rating criteria.'
  },
  {
    icon: Users,
    title: 'Community Driven',
    description: 'Join a vibrant community of travelers sharing experiences and helping others find their perfect stay.'
  }
];

export default LandingPage;