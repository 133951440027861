// src/components/contact/ContactFormLayout.tsx
import React from 'react';
import { MailOpen } from 'lucide-react';
import ContactFormMain from './ContactFormMain';
import ContactFormSidebar from './ContactFormSidebar';

interface InitialData {
  name?: string;
  email?: string;
}

interface ContactFormLayoutProps {
  initialData?: InitialData;
}

const ContactFormLayout: React.FC<ContactFormLayoutProps> = ({ initialData = {} }) => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gray-100 mb-4">
          <MailOpen className="w-8 h-8 text-gray-900" />
          </div>
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Contact Us
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Have a question or feedback? We'd love to hear from you. 
            We'll make sure your message gets to the right team.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <ContactFormMain initialData={initialData} />
          </div>
          <div>
            <ContactFormSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactFormLayout;