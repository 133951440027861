// src/components/analytics/Analytics.jsx
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const GA_TRACKING_ID = import.meta.env.VITE_GA_TRACKING_ID;

// Инициализация Google Analytics
export const initGA = () => {
  if (!GA_TRACKING_ID) {
    console.warn('Google Analytics Tracking ID not found');
    return;
  }

  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', GA_TRACKING_ID, {
    send_page_view: false // Отключаем автоматическую отправку pageview
  });

  window.gtag = gtag;
};

// Отправка события просмотра страницы
export const pageview = (path) => {
  if (!window.gtag) {
    console.warn('Google Analytics not initialized');
    return;
  }
  
  window.gtag('event', 'page_view', {
    page_path: path,
    send_to: GA_TRACKING_ID
  });
};

// Отправка пользовательского события
export const event = ({ action, category, label, value }) => {
  if (!window.gtag) {
    console.warn('Google Analytics not initialized');
    return;
  }

  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value
  });
};

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Инициализируем GA при первой загрузке
    initGA();
  }, []);

  useEffect(() => {
    // Отправляем pageview при изменении маршрута
    pageview(location.pathname + location.search);
  }, [location]);

  return null;
};

export default Analytics;