// src/services/api/hotels/HotelService.ts

import amadeusCache from '../amadeus/cache';
import amadeusApi from '../amadeus';
import { extractCityCode as extractCityCodeUtil } from './utils/cityUtils';
import { processResults } from './utils/resultProcessor';
import { calculateHotelScore, filterHotels } from './methods/scoringMethods';
import hotelImageService from '../hotelImageService';
import { AMADEUS_API } from '../../../config/apiConstants';
import { 
  CategoryId, 
  CategoryCriteria, 
  HotelPreview,
  LocationType,
  AmenityType 
} from '@/types/hotelCategories';

interface SearchParams {
  destination: string;
  checkIn?: string | Date;
  checkOut?: string | Date;
  guests?: number;
  rooms?: number;
  preferences?: UserPreferences;
  filters?: {
    currency?: string;
    ratings?: number[];
    amenities?: string[];
    priceRange?: [number, number];
    radius?: number;
    categoryId?: CategoryId;
  };
}

interface UserPreferences {
  [key: string]: any;
}

class HotelService {
  private static _instance: HotelService;
  private cache: any;
  private amadeus: any;

  constructor() {
    if (HotelService._instance) {
      return HotelService._instance;
    }
    this.initialize();
    HotelService._instance = this;
    return this;
  }

  private initialize(): void {
    try {
      this.cache = amadeusCache;
      this.amadeus = amadeusApi;
    } catch (error) {
      console.error('Error initializing HotelService:', error);
      this.initializeEmptyCache();
    }
  }

  async searchHotels(params: SearchParams) {
    const {
      destination,
      checkIn,
      checkOut,
      guests = AMADEUS_API.DEFAULTS.ADULTS,
      rooms = AMADEUS_API.DEFAULTS.ROOMS,
      preferences,
      filters = {}
    } = params;

    try {
      const cityCode = await this.extractCityCode(destination);
      const cacheKey = this.generateCacheKey({ 
        cityCode, 
        checkIn, 
        checkOut, 
        guests, 
        rooms, 
        filters 
      });
      
      const cached = this.cache.get(cacheKey, 'search');
      if (cached) return this.processResults(cached, preferences, filters);

      const response = await this.amadeus.searchHotels({
        cityCode,
        checkInDate: checkIn,
        checkOutDate: checkOut,
        adults: guests,
        rooms,
        currency: filters.currency || AMADEUS_API.DEFAULTS.CURRENCY,
        ratings: filters.ratings || [],
        amenities: filters.amenities || [],
        priceRange: filters.priceRange,
        radius: filters.radius || AMADEUS_API.DEFAULTS.RADIUS
      });

      const hotels = this.processApiResponse(response);
      const enrichedHotels = await this.enrichHotelsData(hotels, cityCode);

      const filteredHotels = filters.categoryId ? 
        this.filterByCategory(enrichedHotels, filters.categoryId) : 
        enrichedHotels;

      this.cache.set(cacheKey, filteredHotels, 'search');
      return this.processResults(filteredHotels, preferences, filters);

    } catch (error) {
      console.error('Error in searchHotels:', error);
      throw error;
    }
  }

  async getHotelDetails(hotelId: string, params: any = {}) {
    try {
      const cacheKey = `hotel_${hotelId}_${JSON.stringify(params)}`;
      const cached = this.cache.get(cacheKey, 'details');
      if (cached) return cached;

      const searchParams = new URLSearchParams({
        hotelIds: hotelId,
        adults: String(params.adults || 2),
        roomQuantity: String(params.rooms || 1),
        currency: params.currency || 'EUR',
        bestRateOnly: 'false',
        view: 'FULL'
      });

      if (params.checkInDate && params.checkOutDate) {
        searchParams.append('checkInDate', this.formatDate(params.checkInDate));
        searchParams.append('checkOutDate', this.formatDate(params.checkOutDate));
      }

      const response = await this.amadeus.getHotelOffers(hotelId, { searchParams });
      const enrichedData = await this.enrichHotelDetails(response);

      this.cache.set(cacheKey, enrichedData, 'details');
      return enrichedData;
    } catch (error) {
      console.error('Error in getHotelDetails:', error);
      throw error;
    }
  }

  async getHotelsByCategory(categoryId: CategoryId, params: Partial<SearchParams> = {}) {
    try {
      // Уберем destination из параметров по умолчанию
      const defaultParams: SearchParams = {
        guests: 2,
        rooms: 1,
        filters: {
          categoryId
        },
        ...params
      };

      // Используем моковые данные для демонстрации
      // TODO: Заменить на реальный API запрос когда будет готов
      const mockHotels: HotelPreview[] = [
        {
          id: '1',
          name: 'Grand Hotel Example',
          thumbnail: '/api/placeholder/400/300',
          location: {
            city: 'Paris',
            country: 'France'
          },
          rating: {
            score: 4.8,
            type: 'Excellent'
          },
          price: {
            current: {
              amount: 299,
              currency: 'EUR'
            }
          }
        },
        // Добавьте больше моковых отелей...
      ];

      return mockHotels;
    } catch (error) {
      console.error(`Error fetching hotels for category ${categoryId}:`, error);
      return [];
    }
}

  private async enrichHotelsData(hotels: any[], cityCode: string): Promise<any[]> {
    return Promise.all(hotels.map(async (hotel) => {
      try {
        const images = await hotelImageService.getHotelImages(hotel);
        return {
          ...hotel,
          images: images.length > 0 ? images : ["/api/placeholder/400/300"]
        };
      } catch (error) {
        console.error('Error enriching hotel data:', error);
        return hotel;
      }
    }));
  }

  private async enrichHotelDetails(response: any) {
    if (!response?.data?.[0]) return null;

    const hotelData = response.data[0];
    const hotel = hotelData.hotel;
    const images = await hotelImageService.getHotelImages(hotel);

    return {
      ...hotelData,
      hotel: {
        ...hotel,
        images: images.length > 0 ? images : ["/api/placeholder/400/300"]
      }
    };
  }

  private filterByCategory(hotels: any[], categoryId: CategoryId): any[] {
    const category = HOTEL_CATEGORIES[categoryId];
    if (!category?.criteria) return hotels;

    return hotels.filter(hotel => this.matchesCriteria(hotel, category.criteria));
  }

  private matchesCriteria(hotel: any, criteria: CategoryCriteria): boolean {
    if (criteria.minRating && hotel.rating?.stars < criteria.minRating) {
      return false;
    }

    if (criteria.requiredAmenities?.length) {
      const hotelAmenities = this.getHotelAmenities(hotel);
      if (!criteria.requiredAmenities.every(amenity => hotelAmenities.includes(amenity))) {
        return false;
      }
    }

    if (criteria.locationTypes?.length) {
      const hotelLocationType = this.getHotelLocationType(hotel);
      if (!criteria.locationTypes.includes(hotelLocationType)) {
        return false;
      }
    }

    if (criteria.priceRange) {
      const price = hotel.price?.current?.amount;
      if (!price) return false;
      
      if (criteria.priceRange.min && price < criteria.priceRange.min) return false;
      if (criteria.priceRange.max && price > criteria.priceRange.max) return false;
    }

    return true;
  }

  private async extractCityCode(input: string): Promise<string> {
    try {
      return await extractCityCodeUtil(input);
    } catch (error) {
      console.error('Error extracting city code:', error);
      throw error;
    }
  }

  private processApiResponse(response: any): any[] {
    return response?.data ? 
      (Array.isArray(response.data) ? response.data : [response.data]) : 
      [];
  }

  private getHotelAmenities(hotel: any): string[] {
    return hotel.amenities || [];
  }

  private getHotelLocationType(hotel: any): string {
    return hotel.locationType || '';
  }

  private formatDate(date: Date | string): string {
    if (!date) return '';
    const d = new Date(date);
    return d.toISOString().split('T')[0];
  }

  private generateCacheKey(params: any): string {
    return JSON.stringify({
      ...params,
      checkIn: params.checkIn ? new Date(params.checkIn).toISOString().split('T')[0] : null,
      checkOut: params.checkOut ? new Date(params.checkOut).toISOString().split('T')[0] : null
    });
  }

  private initializeEmptyCache(): void {
    this.cache = {
      get: () => null,
      set: () => {},
      clear: () => {},
      size: 0,
      keys: [],
      durations: {}
    };
  }

  processResults(hotels: any[], preferences: UserPreferences, filters: any) {
    try {
      return processResults(hotels, preferences, filters);
    } catch (error) {
      console.error('Error in processResults:', error);
      return { 
        hotels: hotels || [], 
        totalCount: hotels?.length || 0,
        filteredCount: hotels?.length || 0
      };
    }
  }

  clearCache(): void {
    if (this.cache?.clear) {
      this.cache.clear();
      console.log('Hotel service cache cleared');
    }
  }

  getServiceStats() {
    return {
      cacheSize: this.cache?.size || 0,
      cacheKeys: this.cache?.keys || [],
      cacheDurations: this.cache?.durations || {}
    };
  }
}

const hotelService = new HotelService();
Object.freeze(hotelService);

export { HotelService };
export default hotelService;